import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import cn from 'classnames';
import React, { FormEvent, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styles from 'styles/pages/SignUp.scss';
import referrerOpts from '../../../constants/referrerOpts.js';
import { AnalyticsEvents } from '../../../types/AnalyticsEvents.js';
import { trackEvent } from '../../lib/analytics.js';
import Button from '../atoms/Button.js';
import LogoTextLink from '../atoms/LogoTextLink.js';
import TextInput from '../atoms/TextInput.js';
import { GoogleButton } from '../molecules/GoogleButton.js';
import PasswordConfirmValidate from '../organisms/PasswordConfirmValidate.js';
import { isValidPassword } from '../_helpers/validatePassword.js';

const SignUp = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [tosChecked, setTosChecked] = useState(false);
    const [ageChecked, setAgeChecked] = useState(false);
    const [referrer, setReferrer] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const createUser = async (e: FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setLoading(true);
            await Auth.signUp({
                username: email,
                password,
                attributes: {
                    name: `${firstName} ${lastName}`,
                    given_name: firstName,
                    family_name: lastName,
                    'custom:referrer': referrer,
                },
                autoSignIn: {
                    enabled: true,
                },
            });

            trackEvent(AnalyticsEvents.accountCreated, {
                sign_up_method: 'Email',
                referrer,
            });

            /**
             * This is necessary because we skip the verification step in the test environment.
             */
            if (process.env.NODE_ENV === 'test') {
                navigate('/dashboard/questions', { replace: true });
                return;
            }

            navigate('/verify-sign-up', { state: { email } });
        } catch (err) {
            setLoading(false);

            let { message } = err;

            /** This is part of the error message returned by the shortAnswerAdminLinkIdpWithUser lambda function in aws. It's set as a pre signup trigger for the app client.
             * We want to prettify that message if a user tries to sign up for an account they previously used Google to create.
             * */
            if (err.message.includes('A user with that email already exists')) {
                message = 'A user with that email already exists!';
            }

            setError(`Problem creating new account: ${message}`);

            setPassword('');
            setConfirmedPassword('');
            setTosChecked(false);
            setAgeChecked(false);
        }
    };

    const continueWithGoogle = () => {
        trackEvent(AnalyticsEvents.accountCreated, {
            sign_up_method: 'Google',
        });

        Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
        });
    };

    return (
        <div className={styles.container}>
            <LogoTextLink linkClassName={styles.logo_text_link_link} />
            <Card className={styles.card}>
                <h1 className={styles.h1}>Teacher Sign Up</h1>
                <p className={styles.caption}>100% free. No credit card required.</p>

                <div className={styles.google_button_container}>
                    <GoogleButton onClick={continueWithGoogle} showTosMessage />
                    <Divider className={styles.divider}>
                        <div>or</div>
                    </Divider>
                </div>
                <form className={styles.form} onSubmit={createUser}>
                    <div
                        className={cn([styles.first_last_input_container, styles.input])}
                    >
                        <TextInput
                            className={cn([
                                styles.first_last_input,
                                styles.first_name_input,
                            ])}
                            type="text"
                            label="First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                            autoFocus
                        />
                        <TextInput
                            className={styles.first_last_input}
                            type="text"
                            label="Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </div>
                    <TextInput
                        className={styles.input}
                        type="email"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />

                    <PasswordConfirmValidate
                        containerClassName={styles.password_confirm_validate}
                        password={password}
                        confirmedPassword={confirmedPassword}
                        onChangePassword={setPassword}
                        onChangeConfirmedPassword={setConfirmedPassword}
                    />

                    <FormControl className={styles.input} required variant="filled">
                        <InputLabel id="referrer-label">
                            How did you hear about us?
                        </InputLabel>
                        <Select
                            value={referrer}
                            labelId="referrer-label"
                            onChange={(e: SelectChangeEvent) =>
                                setReferrer(e.target.value)
                            }
                        >
                            {referrerOpts.map((opt) => {
                                return (
                                    <MenuItem key={opt} value={opt}>
                                        {opt}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>

                    <FormControlLabel
                        className={styles.checkbox}
                        label={
                            <p>
                                I have read and agree to the{' '}
                                <a
                                    href="https://myshortAnswer.com/terms-of-service/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    terms of service{' '}
                                </a>
                                and{' '}
                                <a
                                    href="https://myshortanswer.com/privacy-policy/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    privacy policy.
                                </a>
                            </p>
                        }
                        control={
                            <Checkbox
                                checked={tosChecked}
                                onChange={(e) => {
                                    setTosChecked(e.target.checked);
                                }}
                            />
                        }
                    />

                    <FormControlLabel
                        className={styles.checkbox}
                        label={<p>I confirm that I am a teacher over 18 years of age.</p>}
                        control={
                            <Checkbox
                                checked={ageChecked}
                                onChange={(e) => {
                                    setAgeChecked(e.target.checked);
                                }}
                            />
                        }
                    />

                    <Button
                        type="submit"
                        kind="primary"
                        className={styles.button}
                        disabled={
                            !isValidPassword(password) ||
                            !email ||
                            password === '' ||
                            confirmedPassword === '' ||
                            password !== confirmedPassword ||
                            !tosChecked ||
                            !ageChecked ||
                            !referrer
                        }
                        loading={loading}
                    >
                        Create free account
                    </Button>
                    {error && (
                        <FormHelperText error className={styles.error_text}>
                            {error}
                        </FormHelperText>
                    )}
                </form>

                <span className={styles.caption_link_container}>
                    <span className={styles.caption_link}>
                        <p>Already have an account?</p>
                        <NavLink to="/sign-in">Sign in here</NavLink>
                    </span>
                    <span className={styles.caption_link}>
                        <p>Not a teacher?</p>
                        <NavLink to="/join">Student sign in</NavLink>
                    </span>
                </span>
            </Card>
        </div>
    );
};

export default SignUp;

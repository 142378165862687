import React from 'react';
import styles from 'styles/pages/room/participant/WaitingRoomAvatarBuilder.scss';
import { AvatarBuilder } from '../../../templates/AvatarBuilder.js';

export const WaitingRoomAvatarBuilder = () => {
    return (
        <div className={styles.container}>
            <AvatarBuilder />
        </div>
    );
};

import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import styles from 'styles/templates/Footer/StudentVerticalThreeDotMenu.scss';
import QRCode from '../../icons/QRCode.js';
import VerticalThreeDotMenu from '../../molecules/VerticalThreeDotMenu.js';
import { ParticipantContext } from '../../context/Participant.js';
import Avatar from '../../organisms/Avatar/index.js';
import useLeaveRoom from '../../hooks/socketEvents/useLeaveRoom.js';
import ConfirmationModal from '../../molecules/modals/ConfirmationModal.js';
import Exclamation from '../../icons/Exclamation.js';
import Exit from '../../icons/Exit.js';

interface StudentVerticalThreeDotMenuProps {
    className?: string;
}

const StudentVerticalThreeDotMenu = ({ className }: StudentVerticalThreeDotMenuProps) => {
    const participant = useContext(ParticipantContext);

    const { joinCode } = useParams();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const navigate = useNavigate();

    const leaveRoom = useLeaveRoom();

    const closeConfirmationModal = () => setShowConfirmationModal(false);

    const exitTeacherRoom = () => {
        leaveRoom({ joinCode, participantId: participant?.id });
        sessionStorage.clear();
        navigate('/join', { replace: true });
    };

    return (
        <>
            <VerticalThreeDotMenu
                className={className}
                menuList={
                    <MenuList className={styles.menu_list}>
                        <div className={styles.header_container}>
                            <Avatar
                                avatarSpecs={participant?.avatar}
                                width={34}
                                className={styles.avatar}
                                id={participant?.id}
                            />
                            <div className={styles.names_container}>
                                <p className={styles.name}>{participant?.name}</p>
                                <p className={styles.code_name}>
                                    {participant?.code_name}
                                </p>
                            </div>
                            <QRCode className={styles.qr_code} />
                            <p className={styles.join_code}>{joinCode}</p>
                        </div>
                        <Divider className={styles.divider} />

                        <MenuItem
                            className={styles.menu_item}
                            onClick={() => setShowConfirmationModal(true)}
                        >
                            <Exit className={styles.exit_icon} />
                            <p className={styles.exit_room}>Exit Room</p>
                        </MenuItem>
                    </MenuList>
                }
            />
            <ConfirmationModal
                open={showConfirmationModal}
                onClose={closeConfirmationModal}
                title="Are you sure you want to leave?"
                content={
                    <p className={styles.exit_dialog_content}>
                        Clicking exit will take you back to the student sign in page.
                        Don&apos;t exit in the middle of the activity!
                    </p>
                }
                image={<Exclamation className={styles.exclamation_icon} />}
                confirmText="Exit anyway"
                cancelText="Go back"
                onConfirm={exitTeacherRoom}
            />
        </>
    );
};

export default StudentVerticalThreeDotMenu;

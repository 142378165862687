import React, { useContext, useState } from 'react';
import Card from '@mui/material/Card';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import styles from 'styles/pages/dashboard/account/Plan.scss';
import { RoomContext } from '../../../context/Room.js';
import Button from '../../../atoms/Button.js';
import { useCreateStripeCheckoutSession } from '../../../hooks/mutations/useCreateStripeCheckoutSession.js';
import { hasPremiumSubscription } from '../../../../../shared/subscriptionStatusHelpers.js';
import { IconLoader } from '../../../atoms/IconLoader.js';
import { useCreateStripeCustomerPortalSession } from '../../../hooks/mutations/useCreateStripeCustomerPortalSession.js';
import { trackEvent } from '../../../../lib/analytics.js';
import { AnalyticsEvents } from '../../../../../types/AnalyticsEvents.js';

export const Plan = () => {
    const room = useContext(RoomContext);
    const [planType, setPlanType] = useState('annual');

    const createStripeCheckoutSession = useCreateStripeCheckoutSession();
    const createStripeCustomerPortalSession = useCreateStripeCustomerPortalSession();

    const handleCheckout = () => {
        trackEvent(AnalyticsEvents.planFreeTrialButtonClicked, {
            planType,
        });

        createStripeCheckoutSession.mutate({
            planType,
        });
    };

    const handleVisitCustomerPortal = () => {
        trackEvent(AnalyticsEvents.planManageSubscriptionLinkClicked);
        createStripeCustomerPortalSession.mutate();
    };

    const isPremium = hasPremiumSubscription(room);

    const plan = isPremium ? 'Premium' : 'Free';

    const planDescription = isPremium
        ? 'You are currently subscribed to Short Answer Premium.'
        : 'You are currently on the free plan with limited features and usage caps.';

    if (
        createStripeCheckoutSession.isLoading ||
        createStripeCustomerPortalSession.isLoading
    ) {
        return <IconLoader className={styles.icon_loader} />;
    }

    return (
        <div className={styles.container}>
            <h1 className={styles.h1}>Plan</h1>
            <Card className={styles.card}>
                <h3 className={styles.h3}>{`${plan} Plan`}</h3>
                <p className={styles.caption}>{planDescription}</p>
                {isPremium && (
                    <div>
                        <button
                            type="button"
                            onClick={handleVisitCustomerPortal}
                            className={styles.button_link}
                        >
                            Manage Subscription
                        </button>
                        <a
                            href="https://myshortanswer.com/see-plans/"
                            target="_blank"
                            rel="noreferrer"
                            className={styles.link}
                        >
                            Compare plans
                        </a>
                    </div>
                )}
            </Card>
            <h2 className={styles.h2}>Unlock the full power of peer feedback</h2>
            <div className={styles.cards_container}>
                {!isPremium && (
                    <Card className={styles.premium_card}>
                        <h3 className={styles.h3}>Premium</h3>
                        <p className={styles.caption}>For individual teachers</p>
                        <div className={styles.pricing_container}>
                            <ToggleButtonGroup
                                className={styles.toggle_group}
                                value={planType}
                                exclusive
                                onChange={(_, update) => update && setPlanType(update)}
                            >
                                <ToggleButton
                                    className={styles.toggle_button}
                                    value="annual"
                                >
                                    Annual
                                </ToggleButton>
                                <ToggleButton
                                    className={styles.toggle_button}
                                    value="monthly"
                                >
                                    Monthly
                                </ToggleButton>
                            </ToggleButtonGroup>
                            {planType === 'monthly' && (
                                <div>
                                    <p>
                                        <span className={styles.price}>$12</span> /month
                                    </p>
                                    <p className={styles.discount}>
                                        $144 a year billed monthly
                                    </p>
                                </div>
                            )}
                            {planType === 'annual' && (
                                <div>
                                    <p>
                                        <span className={styles.price}>$6.25</span> /month
                                    </p>
                                    <p className={styles.discount}>
                                        $75 a year billed annually
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className={styles.features_list_container}>
                            <h4 className={styles.h4}>
                                Premium includes all free features, plus:
                            </h4>
                            <ul className={styles.features_list}>
                                <li className={styles.features_list_item}>
                                    Unlimited saved questions
                                </li>
                                <li className={styles.features_list_item}>
                                    Early access to future premium features
                                </li>
                            </ul>
                        </div>
                        <Button
                            className={styles.button}
                            onClick={handleCheckout}
                            loading={createStripeCheckoutSession.isLoading}
                        >
                            Upgrade to Premium
                        </Button>
                    </Card>
                )}
                <Card className={styles.school_plan_card}>
                    <div className={styles.school_plan_header_container}>
                        <h3 className={styles.h3}>School Plan</h3>
                        <p className={styles.caption}>For schools and districts</p>
                    </div>
                    <div className={styles.school_plan_content_container}>
                        <h4 className={styles.h4}>Custom Pricing</h4>
                        <p className={styles.caption}>Volume discounts apply</p>
                    </div>
                    <Button
                        className={styles.school_plan_button}
                        to="https://myshortanswer.com/inquiries/"
                        // @ts-ignore
                        target="_blank"
                        rel="noreferrer"
                    >
                        Get a Quote
                    </Button>
                </Card>
            </div>
        </div>
    );
};

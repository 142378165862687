import React from 'react';
import { WaitScreen } from '../../../templates/WaitScreen.js';

export interface WaitingRoomProps {
    message?: string;
}

export const WaitingRoom = ({ message }: WaitingRoomProps) => {
    const messageToDisplay =
        message || 'Waiting to begin the activity. By the way, you rock. Keep being you.';

    return <WaitScreen message={messageToDisplay} loaderColor="yellow" />;
};

import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import styles from 'styles/templates/Sidebar/TopNavLink.scss';
import Badge from '@mui/material/Badge';
import { Tooltip } from '../../atoms/Tooltip.js';

export interface TopNavLinkProps {
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    text: string;
    link: string;
    expanded: boolean;
    showTooltip?: boolean;
    className?: string;
    showNotificationBadge?: boolean;
    notificationCount?: number;
}

const renderNavLink = ({
    icon,
    text,
    link,
    expanded,
    className,
    showNotificationBadge,
    notificationCount,
}: TopNavLinkProps) => {
    const Icon = icon;
    return (
        <NavLink to={link} className={cn([styles.nav_link, className])}>
            {({ isActive }) => (
                <div
                    className={cn([
                        styles.nav_link_content_container,
                        {
                            [styles.nav_link_active]: isActive,
                        },
                    ])}
                >
                    <div className={styles.nav_link_icon_container}>
                        <Badge
                            badgeContent={notificationCount}
                            className={styles.notification_badge}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            invisible={!showNotificationBadge || notificationCount === 0}
                        >
                            <Icon className={styles.nav_link_icon} />
                        </Badge>
                    </div>
                    <p
                        className={cn([
                            styles.nav_link_text,
                            {
                                [styles.hidden]: !expanded,
                            },
                        ])}
                    >
                        {text}
                    </p>
                </div>
            )}
        </NavLink>
    );
};

export const TopNavLink = (topNavLinkProps: TopNavLinkProps) => {
    const { text, showTooltip, expanded } = topNavLinkProps;
    return showTooltip && !expanded ? (
        <Tooltip
            tooltipTitle={text}
            tooltipParent={renderNavLink(topNavLinkProps)}
            tooltipClassName={styles.tooltip}
            tooltipArrowClassName={styles.tooltip_arrow}
        />
    ) : (
        renderNavLink(topNavLinkProps)
    );
};

import React, { FormEvent, useState } from 'react';
import Card from '@mui/material/Card';
import FormHelperText from '@mui/material/FormHelperText';
import styles from 'styles/templates/UpdatePasswordForm.scss';
import TextInput from '../atoms/TextInput.js';
import PasswordConfirmValidate from '../organisms/PasswordConfirmValidate.js';
import Button from '../atoms/Button.js';
import { User } from '../../../types/User.js';

export interface UpdatePasswordFormProps {
    onFormSubmit: (updatedAttributes: User['attributes']) => Promise<void>;
}

const UpdatePasswordForm = ({ onFormSubmit }) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        try {
            await onFormSubmit({ oldPassword, newPassword });
        } catch (err) {
            setError(`Error updating password: ${err.message}`);
        }

        setOldPassword('');
        setNewPassword('');
        setConfirmedPassword('');
        setLoading(false);
    };

    return (
        <>
            <h2 className={styles.h2}>Password</h2>
            <Card className={styles.card}>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <TextInput
                        className={styles.input}
                        value={oldPassword}
                        required
                        onChange={(e) => setOldPassword(e.target.value)}
                        label="Old Password"
                        type="password"
                        variant="outlined"
                    />
                    <span className={styles.input}>
                        <PasswordConfirmValidate
                            password={newPassword}
                            onChangePassword={setNewPassword}
                            confirmedPassword={confirmedPassword}
                            passwordFieldLabel="New Password"
                            confirmPasswordFieldLabel="Confirm New Password"
                            onChangeConfirmedPassword={setConfirmedPassword}
                        />
                    </span>
                    <div className={styles.button_container}>
                        {error && (
                            <FormHelperText className={styles.error_text} error>
                                {error}
                            </FormHelperText>
                        )}
                        <Button
                            className={styles.button}
                            loading={loading}
                            type="submit"
                            disabled={
                                newPassword !== confirmedPassword ||
                                oldPassword === '' ||
                                newPassword === '' ||
                                confirmedPassword === ''
                            }
                            kind="primary"
                        >
                            Change Password
                        </Button>
                    </div>
                </form>
            </Card>
        </>
    );
};

export default UpdatePasswordForm;

import React from 'react';
import styles from 'styles/templates/WaitScreen.scss';
import LoaderMessage from '../molecules/LoaderMessage.js';
import LogoIcon from '../icons/Logo.js';
import StudentVerticalThreeDotMenu from './Footer/StudentVerticalThreeDotMenu.js';

interface WaitScreenProps {
    /** Message to display on waiting screen */
    message?: string | JSX.Element;
    /** determinate stops the loader from spinning, indeterminate by default */
    variant?: 'determinate' | 'indeterminate';
    /** Color of the spinner, 'success' by default */
    loaderColor?: 'yellow' | 'white';
    /** If true, the logo will be displayed instead of the circular loader */
    showLogo?: boolean;
}

export const WaitScreen = ({
    message,
    loaderColor = 'yellow',
    variant = 'determinate',
    showLogo,
}: WaitScreenProps) => {
    return (
        <>
            <div className={styles.three_dot}>
                <StudentVerticalThreeDotMenu />
            </div>
            <div className={styles.container}>
                {showLogo && (
                    <div className={styles.logo_container}>
                        <LogoIcon className={styles.logo_icon} />
                        <p className={styles.logo_text}>{message}</p>
                    </div>
                )}
                {!showLogo && (
                    <LoaderMessage
                        message={message}
                        variant={variant}
                        loaderColor={loaderColor}
                    />
                )}
            </div>
        </>
    );
};

import React, { FormEvent, useEffect, useState, useContext } from 'react';
import { useQueryClient } from 'react-query';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import styles from 'styles/templates/UpdateProfileForm.scss';
import { captureException } from '@sentry/react';
import TextInput from '../atoms/TextInput.js';
import Button from '../atoms/Button.js';
import { User } from '../../../types/User.js';
import { SubjectsList, Room, Grade } from '../../../types/Room.js';
import Pencil from '../icons/Pencil.js';
import { ProfileImage } from '../atoms/ProfileImage.js';
import { uploadProfileImage } from '../_helpers/uploadProfileImage.js';
import { useProfileImage } from '../hooks/queries/useProfileImage.js';
import ThreeDotLoader from '../atoms/ThreeDotLoader.js';
import { isValidProfileImageFileType } from '../_helpers/fileValidationHelpers.js';
import { StateDropdown } from '../molecules/StateDropdown.js';
import { SubjectDropdown } from '../molecules/SubjectDropdown.js';
import { RoomContext } from '../context/Room.js';
import { GradeChecklist } from '../molecules/GradeChecklist.js';

export interface UpdateProfileFormProps {
    attributes: User['attributes'] | null;
    onFormSubmit: (
        userAttributes: User['attributes'],
        room: Partial<Room>,
    ) => Promise<void>;
}

const UpdateProfileForm = ({ attributes, onFormSubmit }: UpdateProfileFormProps) => {
    const queryClient = useQueryClient();
    const room = useContext(RoomContext);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gradesList, setGradesList] = useState<Grade[]>([]);
    const [subjectsList, setSubjectsList] = useState<SubjectsList>({});
    const [teachingState, setTeachingState] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [loadingImagePreview, setLoadingImagePreview] = useState(false);
    const [error, setError] = useState('');
    const [profileImage, setProfileImage] = useState('');

    const {
        data: profileImageData,
        isLoading: isLoadingProfileImage,
        isError: isProfileImageError,
    } = useProfileImage();

    const handleSetProfileImage = (fileToPreview: File) => {
        const reader = new FileReader();
        reader.readAsDataURL(fileToPreview);
        reader.onloadend = () => {
            setProfileImage(reader.result as string);
            setLoadingImagePreview(false);
        };
    };

    useEffect(() => {
        if (!profileImageData || isLoadingProfileImage) return;

        // This means the user has not uploaded a profile image yet
        if (isProfileImageError) {
            setLoadingImagePreview(false);
        }

        setLoadingImagePreview(true);

        const profileImageFromS3 = new File([profileImageData], 'profile-image', {
            type: profileImageData.type,
        });

        handleSetProfileImage(profileImageFromS3);
    }, [profileImageData?.size]);

    useEffect(() => {
        if (attributes) {
            if (attributes?.given_name) {
                setFirstName(attributes.given_name);
            }

            if (attributes?.family_name) {
                setLastName(attributes.family_name);
            }
        }
    }, [attributes]);

    useEffect(() => {
        if (room?.state) {
            setTeachingState(room.state);
        }
        if (room?.grades_taught) {
            setGradesList(room.grades_taught);
        }
        if (room?.subjects_taught) {
            setSubjectsList(room.subjects_taught);
        }
    }, [room?.id]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        try {
            await onFormSubmit(
                {
                    given_name: firstName,
                    family_name: lastName,
                },
                {
                    grades_taught: gradesList,
                    subjects_taught: subjectsList,
                    state: teachingState,
                },
            );
        } catch (err) {
            setError(`Error updating profile: ${err.message}`);
            captureException(err);
        }

        setLoading(false);
    };

    const uploadProfilePic = async (e) => {
        if (!e.target.files[0]) return;

        setLoadingImagePreview(true);

        if (!isValidProfileImageFileType(e.target.files[0])) {
            setError('Invalid file type. Please upload a JPEG, PNG, or WEBP file.');
            setLoadingImagePreview(false);
            return;
        }

        const selectedFile = e.target.files[0];

        await uploadProfileImage(selectedFile);

        queryClient.invalidateQueries('profile-image');

        handleSetProfileImage(selectedFile);
    };

    const hasGradeListChanged = () => {
        return JSON.stringify(gradesList) === JSON.stringify(room?.grades_taught);
    };

    const hasSubjectsListChanged = (
        subjectList1: SubjectsList,
        subjectList2: SubjectsList,
    ) => {
        if (subjectList1 === null || subjectList2 === null) {
            return false;
        }
        if (JSON.stringify(subjectList1) === JSON.stringify(subjectList2)) {
            return true;
        }
        const grades = Object.keys(subjectList1);
        grades.forEach((grade) => {
            if (subjectList1[grade]?.sort() !== subjectList2[grade]?.sort()) {
                return false;
            }
        });
    };

    const updateButtonDisabled =
        firstName === attributes?.given_name &&
        lastName === attributes?.family_name &&
        teachingState === room?.state &&
        hasGradeListChanged() &&
        hasSubjectsListChanged(subjectsList, room?.subjects_taught);

    return (
        <>
            <h2 className={styles.h2}>Profile</h2>
            <Card className={styles.card}>
                <div className={styles.account_default_container}>
                    <ProfileImage
                        src={profileImage}
                        accountDefaultClassName={styles.account_default}
                        profileImageClassName={styles.profile_image}
                    />
                    {(isLoadingProfileImage || loadingImagePreview) && (
                        <div className={styles.loader_container}>
                            <ThreeDotLoader />
                        </div>
                    )}
                    <IconButton
                        component={InputLabel}
                        htmlFor="profile-pic"
                        className={styles.pencil_container}
                    >
                        <input
                            type="file"
                            hidden
                            id="profile-pic"
                            onChange={uploadProfilePic}
                        />
                        <Pencil className={styles.pencil} />
                    </IconButton>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className={styles.input_container}>
                        <TextInput
                            label="First Name"
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                            className={styles.input}
                            variant="outlined"
                        />
                        <TextInput
                            label="Last Name"
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                            className={styles.input}
                            variant="outlined"
                        />
                    </div>
                    {!room ? (
                        <ThreeDotLoader />
                    ) : (
                        <>
                            <div className={styles.input_container}>
                                <StateDropdown
                                    teachingState={teachingState}
                                    setTeachingState={setTeachingState}
                                    className={styles.state_grade_dropdown}
                                />
                            </div>
                            <div className={styles.input_container}>
                                <GradeChecklist
                                    selectedGrades={gradesList}
                                    onChange={setGradesList}
                                    className={styles.state_grade_dropdown}
                                />
                            </div>
                            <div className={styles.input_container}>
                                <SubjectDropdown
                                    gradeList={gradesList}
                                    setSubjects={setSubjectsList}
                                    subjects={subjectsList}
                                    formControlClassName={styles.subject_form_control}
                                    hideHeading
                                    className={styles.state_grade_dropdown}
                                />
                            </div>
                        </>
                    )}
                    <div className={styles.button_container}>
                        {error && (
                            <FormHelperText error className={styles.error_text}>
                                {error}
                            </FormHelperText>
                        )}
                        <Button
                            loading={loading}
                            type="submit"
                            kind="primary"
                            className={styles.button}
                            disabled={updateButtonDisabled}
                        >
                            Update Profile
                        </Button>
                    </div>
                </form>
            </Card>
        </>
    );
};

export default UpdateProfileForm;

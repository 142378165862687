import React, { useEffect, useContext, useState, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Skeleton from '@mui/material/Skeleton';
import styles from 'styles/templates/AvatarBuilder.scss';
import cn from 'classnames';
import Fade from '@mui/material/Fade';
import { avatarBackgroundColors } from '../../../constants/avatarBackgroundColors.js';
import { useUpdateParticipant } from '../hooks/mutations/useUpdateParticipant.js';
import Avatar from '../organisms/Avatar/index.js';
import { AvatarSpecs } from '../../../types/Participant.js';
import { ParticipantContext, SetParticipantContext } from '../context/Participant.js';
import { allFaces } from '../organisms/Avatar/faces/allFaces.js';
import { Gesture } from '../organisms/Avatar/Gesture.js';
import { Shape } from '../organisms/Avatar/Shape.js';
import Neither from '../icons/Neither.js';
import Button from '../atoms/Button.js';

type TabValue = 'background' | 'shape' | 'face' | 'gesture' | 'color';

export interface AvatarBuilderProps {
    onClickDone?: () => void;
}

export const AvatarBuilder = ({ onClickDone }: AvatarBuilderProps) => {
    const participant = useContext(ParticipantContext);
    const setParticipant = useContext(SetParticipantContext);
    const avatarOptionsRef = useRef(null);

    const [tabValue, setTabValue] = useState<TabValue>('shape');
    const [avatarSpecs, setAvatarSpecs] = useState<AvatarSpecs | undefined>();
    const [showScrollShadow, setShowScrollShadow] = useState(true);

    const participantMutation = useUpdateParticipant();

    useEffect(() => {
        if (avatarSpecs === undefined) {
            setAvatarSpecs(participant?.avatar);
        }
    }, [participant?.id, avatarSpecs]);

    useEffect(() => {
        if (avatarOptionsRef?.current) {
            avatarOptionsRef.current.addEventListener('scroll', () => {
                const isAtBottom =
                    avatarOptionsRef.current.scrollHeight -
                        avatarOptionsRef.current.scrollTop <=
                    avatarOptionsRef.current.clientHeight + 1;

                if (isAtBottom) {
                    setShowScrollShadow(false);
                } else if (!showScrollShadow) {
                    setShowScrollShadow(true);
                }
            });
        }
    }, [avatarOptionsRef?.current]);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: TabValue) => {
        setTabValue(newValue);
    };

    const updateAvatarSpecs = (spec: Partial<AvatarSpecs>) => {
        setAvatarSpecs({
            ...avatarSpecs,
            ...spec,
        });
    };

    const showAvatar =
        participant?.avatar?.bgColor &&
        participant?.avatar?.shape &&
        participant?.avatar?.face &&
        participant?.avatar?.shapeColor;

    const handleClick = async () => {
        const updatedParticipant = await participantMutation.mutateAsync({
            updatedParticipant: {
                ...participant,
                avatar: avatarSpecs,
            },
        });

        setParticipant(updatedParticipant?.participant);

        onClickDone?.();
    };

    const shapeColors: AvatarSpecs['shapeColor'][] = [
        'blue',
        'red',
        'pink',
        'green',
        'purple',
        'orange',
    ];

    return (
        <span className={styles.container}>
            <span className={styles.avatar_container}>
                <div className={styles.avatar}>
                    {showAvatar ? (
                        <div>
                            <Avatar
                                id="main_avatar"
                                avatarSpecs={avatarSpecs}
                                width={150}
                            />
                        </div>
                    ) : (
                        <Skeleton
                            className={styles.skeleton}
                            variant="circular"
                            width={150}
                            height={150}
                        />
                    )}
                </div>
            </span>
            <h2 className={styles.code_name}>
                Psst...your code name is <strong>{participant?.code_name}</strong>
            </h2>
            <Tabs
                variant="scrollable"
                value={tabValue}
                onChange={handleChangeTab}
                className={styles.tab_container}
                scrollButtons
                allowScrollButtonsMobile
            >
                <Tab className={styles.tab} label="Shape" value="shape" />
                <Tab className={styles.tab} label="Face" value="face" />
                <Tab className={styles.tab} label="Gesture" value="gesture" />
                <Tab className={styles.tab} label="Color" value="color" />
                <Tab className={styles.tab} label="Background" value="background" />
            </Tabs>
            <Fade in key={tabValue} timeout={500}>
                <div className={styles.options_container}>
                    <div ref={avatarOptionsRef} className={styles.avatar_options}>
                        {tabValue === 'shape' &&
                            ['circle', 'square', 'diamond', 'rounded'].map(
                                (shape: AvatarSpecs['shape']) => {
                                    return (
                                        <IconButton
                                            className={styles.option}
                                            key={shape}
                                            onClick={() => updateAvatarSpecs({ shape })}
                                        >
                                            <Avatar
                                                id={`${shape}-option`}
                                                avatarSpecs={{
                                                    bgColor: '',
                                                    shapeColor: avatarSpecs?.shapeColor,
                                                    shape,
                                                    face: '',
                                                    gesture: '',
                                                }}
                                                width={100}
                                            />
                                        </IconButton>
                                    );
                                },
                            )}
                        {tabValue === 'face' &&
                            Object.keys(allFaces).map((faceId) => {
                                return (
                                    <IconButton
                                        id={`${faceId}-button`}
                                        className={styles.no_padding}
                                        key={faceId}
                                        onClick={() =>
                                            updateAvatarSpecs({ face: faceId })
                                        }
                                    >
                                        <Avatar
                                            id={`${faceId}-option`}
                                            avatarSpecs={{
                                                bgColor: '',
                                                shapeColor: avatarSpecs?.shapeColor,
                                                shape: avatarSpecs?.shape,
                                                face: faceId,
                                                gesture: '',
                                            }}
                                            width={125}
                                        />
                                    </IconButton>
                                );
                            })}
                        {tabValue === 'gesture' &&
                            [...Array.from(Array(7).keys())].map((num) => {
                                const id = num === 6 ? '' : `gesture-${num}`;

                                return (
                                    <IconButton
                                        className={cn([styles.option])}
                                        key={id}
                                        onClick={() => {
                                            updateAvatarSpecs({ gesture: id });
                                        }}
                                    >
                                        {num < 6 && (
                                            <svg
                                                width={65}
                                                height={65}
                                                viewBox="0 0 50 50"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <Gesture
                                                    gesture={id}
                                                    avatarId={`${id}_option`}
                                                    color={avatarSpecs?.shapeColor}
                                                    className={styles.gesture}
                                                />
                                            </svg>
                                        )}
                                        {num === 6 && (
                                            <Neither className={styles.neither} />
                                        )}
                                    </IconButton>
                                );
                            })}
                        {tabValue === 'color' &&
                            shapeColors.map((shapeColor) => {
                                return (
                                    <IconButton
                                        className={cn([styles.option, styles.no_padding])}
                                        key={`${shapeColor}-option`}
                                        onClick={() => updateAvatarSpecs({ shapeColor })}
                                    >
                                        <svg
                                            width={110}
                                            height={110}
                                            viewBox="0 0 200 200"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <Shape
                                                shape={avatarSpecs?.shape}
                                                color={shapeColor}
                                                avatarId={`${shapeColor}_option`}
                                                hasGesture={false}
                                            />
                                        </svg>
                                    </IconButton>
                                );
                            })}
                        {tabValue === 'background' &&
                            avatarBackgroundColors.map((color, index) => {
                                return (
                                    <IconButton
                                        className={styles.option}
                                        key={color}
                                        onClick={() =>
                                            updateAvatarSpecs({ bgColor: color })
                                        }
                                    >
                                        <div
                                            id={`bg-color-option-${index}`}
                                            style={{
                                                backgroundColor: color,
                                            }}
                                            className={styles.bg_color}
                                        />
                                    </IconButton>
                                );
                            })}
                    </div>
                    <div
                        className={cn({
                            [styles.scroll_shadow]: showScrollShadow,
                        })}
                    />
                </div>
            </Fade>
            <Button
                kind="primary"
                loading={participantMutation.isLoading}
                onClick={handleClick}
                className={styles.done_button}
            >
                Done
            </Button>
        </span>
    );
};

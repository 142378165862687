import React, { MouseEvent, useContext, useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import DialogContent from '@mui/material/DialogContent';
import styles from 'styles/pages/room/participant/AddResponse/AddResponse.scss';
import { RESPONSE } from '../../../../../../constants/channels.js';
import { SocketResponse } from '../../../../../../types/Socket.js';
import { WritingScaffolds } from '../../../../../../types/WritingScaffolds.js';
import { BadWordsError } from '../../../../atoms/BadWordsError.js';
import TextAreaInput from '../../../../atoms/TextAreaInput.js';
import { ParticipantContext } from '../../../../context/Participant.js';
import { SessionContext } from '../../../../context/Session.js';
import { SocketContext } from '../../../../context/Socket.js';
import { useAddResponse } from '../../../../hooks/mutations/useAddResponse.js';
import { useAIProficiencyFeedbackForResponse } from '../../../../hooks/mutations/useAIProficiencyFeedbackForResponse.js';
import QuestionHeader from '../../../../molecules/AddResponseQuestionHeader.js';
import AddResponseToolbar from '../../../../molecules/AddResponseToolbar.js';
import { checkForBadWords } from '../../../../../../shared/checkForBadWords.js';
import { Dialog } from '../../../../atoms/Dialog.js';
import NumberSlider from '../../../../atoms/NumberSlider.js';
import Button from '../../../../atoms/Button.js';
import { SubmissionConfirmation } from './SubmissionConfirmation.js';
import { CustomListSplashScreen } from '../../../../organisms/CustomListSplashScreen.js';
import { convertActivitySlugToPlainText } from '../../../../_helpers/convertActivitySlugToPlainText.js';
import { WaitScreen } from '../../../../templates/WaitScreen.js';

const AddResponse = () => {
    const socket = useContext(SocketContext);
    const participant = useContext(ParticipantContext);
    const session = useContext(SessionContext);

    const { joinCode } = useParams();

    const [response, setResponse] = useState('');
    const [error, setError] = useState('');
    const [confidenceScore, setConfidenceScore] = useState(null);
    const [filesToUpload, setFilesToUpload] = useState<FileList>(null);
    const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
    const [selectedScaffolds, setSelectedScaffolds] = useState<
        Array<{
            // Scaffold key
            scaffold: keyof WritingScaffolds;
            // Scaffold content
            content: string;
            // Determine whether or not the scaffold is displayed in the UI
            isActive: boolean;
            // Track scaffold loading state
            isLoading: boolean;
            // Whether the student used the scaffold at all (i.e. clicked on it) while writing their response
            wasUsed: boolean;
        }>
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingParticipant, setIsLoadingParticipant] = useState(true);
    const [showRateConfidenceModal, setShowRateConfidenceModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const addResponse = useAddResponse();
    const addFeedback = useAIProficiencyFeedbackForResponse(joinCode);

    useEffect(() => {
        if (session?.writing_scaffolds) {
            const enabledScaffolds = Object.keys(session?.writing_scaffolds ?? {}).filter(
                (scaffold) => !!session?.writing_scaffolds[scaffold],
            ) as Array<keyof WritingScaffolds>;

            const update = enabledScaffolds.map((scaffold) => {
                return {
                    scaffold,
                    content: session?.question_writing_scaffolds?.[scaffold],
                    isActive: false,
                    isLoading: false,
                    wasUsed: false,
                };
            });

            setSelectedScaffolds(update);
        }
    }, [session]);

    useEffect(() => {
        if (participant?.id) {
            setIsLoadingParticipant(false);
        }
    }, [participant]);

    if (isLoadingParticipant) {
        return <WaitScreen message="Loading..." />;
    }

    if (isSubmitted) {
        return <SubmissionConfirmation />;
    }

    const extractUsedScaffolds = () => {
        const usedScaffolds = {
            'sentence-stems': false,
            outline: false,
            translate: false,
        };

        selectedScaffolds.forEach((scaffold) => {
            usedScaffolds[scaffold.scaffold] = scaffold.wasUsed;
        });

        return usedScaffolds;
    };

    const handleSubmitResponse = async (
        event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    ) => {
        event.preventDefault();

        const reqPayload = {
            responseText: response,
            confidenceScore,
            filesToUpload,
            writingScaffolds: extractUsedScaffolds(),
            participantId: participant?.id,
            sessionId: session?.id,
        };

        return addResponse.mutateAsync(reqPayload, {
            onSuccess: (data) => {
                const { response: newResponse } = data;

                if (session?.activity_slug === 'quick-write') {
                    addFeedback.mutate({
                        responseId: newResponse.id,
                        sessionId: session.id,
                    });
                }

                socket?.emit(
                    RESPONSE.ADDED,
                    { joinCode },
                    (socketResponse: SocketResponse) => {
                        if (socketResponse?.error) {
                            captureException(socketResponse.error);
                        }
                    },
                );
            },
        });
    };

    const handleCheckForBadWords = () => {
        try {
            checkForBadWords(response);
        } catch (err) {
            setError(err.message);
            return;
        }

        setShowRateConfidenceModal(true);
    };

    const criteria = session?.feedback_opts?.values ?? [];

    const handleCopyPaste = (event) => {
        if (!session?.allow_copy_paste) {
            event.preventDefault();
            setError('Copy and paste has been disabled for this activity.');
        }
    };

    const handleClickScaffold = (scaffold: keyof WritingScaffolds) => {
        const selectedScaffold = selectedScaffolds?.find((s) => s.scaffold === scaffold);

        setSelectedScaffolds(
            selectedScaffolds.map((ss) => {
                if (ss.scaffold === scaffold) {
                    const isActive = !selectedScaffold.isActive;

                    const update = {
                        ...ss,
                        isActive,
                    };

                    if (isActive && !ss.wasUsed) {
                        update.wasUsed = true;
                    }

                    return update;
                }

                return ss;
            }),
        );
    };

    const handleTextFieldFocus = (
        e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
        val: boolean,
    ) => {
        e.stopPropagation();
        const target = e.target as Element;

        if (
            target.nodeName === 'BUTTON' ||
            target.nodeName === 'svg' ||
            target.nodeName === 'path'
        ) {
            /** Don't set focus in text area if the user is clicking on a button/ svg element */
            return;
        }

        setIsTextFieldFocused(val);
    };

    const handleSubmit = async (
        e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    ) => {
        setIsLoading(true);

        try {
            await handleSubmitResponse(e);
        } catch (err) {
            setError('Error submitting response. Please try again.');
            setIsLoading(false);
            return;
        }

        setIsLoading(false);
        setIsSubmitted(true);
    };

    const visibleScaffoldContent = selectedScaffolds.filter((scaffold) => {
        return scaffold.isActive || scaffold.isLoading;
    });

    const enabledScaffolds = Object.keys(session?.writing_scaffolds ?? {}).filter(
        (scaffold) => !!session?.writing_scaffolds[scaffold],
    ) as Array<keyof WritingScaffolds>;

    return (
        <>
            <form className={styles.form}>
                <QuestionHeader
                    questionText={session?.question_text}
                    className={cn([
                        {
                            [styles.header_card]: !isTextFieldFocused,
                            [styles.header_card_xs]: isTextFieldFocused,
                        },
                    ])}
                    criteria={criteria}
                    session={session}
                />

                <div
                    className={cn([
                        styles.response_content_container,
                        {
                            [styles.two_cols_w_toolbar]: enabledScaffolds?.length > 0,
                        },
                    ])}
                >
                    <div
                        className={cn([
                            styles.response_input_container,
                            {
                                [styles.response_content_grid]:
                                    visibleScaffoldContent.length,
                                [styles.response_scaffold_grid]:
                                    !visibleScaffoldContent.length,
                                [styles.response_input_grid]: !enabledScaffolds?.length,
                            },
                        ])}
                    >
                        <TextAreaInput
                            id="response"
                            onClick={handleTextFieldFocus}
                            onChange={(text) => {
                                setError('');
                                setResponse(text);
                            }}
                            className={cn([
                                styles.response_input,
                                {
                                    [styles.text_area_next]: isTextFieldFocused,
                                },
                            ])}
                            // onPaste={handleCopyPaste}
                            defaultValue={response}
                            onClickNextButton={handleCheckForBadWords}
                            placeholder="Write your response here..."
                            showMathToolbarIcon
                            setFilesToUpload={setFilesToUpload}
                            // We only want to show the cached file when the user loads the page
                            fileList={filesToUpload}
                            fileValidationOptions={{
                                isStudentFile: true,
                                maxFileCount: 1,
                                maxFileSize: 5,
                            }}
                            onPaste={handleCopyPaste}
                            onError={setError}
                        />
                        <AddResponseToolbar
                            enabledScaffolds={enabledScaffolds}
                            onClickScaffold={handleClickScaffold}
                            response={response}
                            isTextFieldFocused={isTextFieldFocused}
                            setIsTextFieldFocused={setIsTextFieldFocused}
                        />
                    </div>
                    <div className={styles.error_text}>
                        <BadWordsError error={error} />
                    </div>
                </div>
            </form>
            <Dialog
                open={showRateConfidenceModal}
                onClose={() => setShowRateConfidenceModal(false)}
            >
                <DialogContent>
                    <h1>Rate your confidence in your response, then submit</h1>
                    <div className={styles.number_slider_container}>
                        <NumberSlider
                            onChangeCommitted={(_, value) =>
                                setConfidenceScore(value as number)
                            }
                            defaultValue={confidenceScore}
                        />
                        <div className={styles.button_container}>
                            <Button
                                kind="text"
                                className={styles.text_button}
                                onClick={() => {
                                    setShowRateConfidenceModal(false);
                                }}
                            >
                                Go back
                            </Button>
                            <Button
                                kind="primary"
                                onClick={handleSubmit}
                                loading={isLoading}
                                disabled={confidenceScore === null}
                            >
                                Submit
                            </Button>
                        </div>
                        <div className={styles.error}>{error}</div>
                    </div>
                </DialogContent>
            </Dialog>
            {!session || session.activity_slug !== 'quick-write' ? null : (
                <CustomListSplashScreen
                    title={`Welcome to ${convertActivitySlugToPlainText(
                        session?.activity_slug,
                    )}`}
                    content={[
                        'You’ll write your response independently',
                        'AI will score the response, earning you points for higher scores',
                        'We’ll add points together as a class to try to earn a goal score. Try your hardest!',
                    ]}
                    ctaText="Got it!"
                />
            )}
        </>
    );
};

export default AddResponse;

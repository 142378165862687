import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import styles from 'styles/templates/AddQuestionPlaceholder.scss';
import LightBulbPencilSVG from '../icons/svgs/LightBulbPencil.svg';
import Button from '../atoms/Button.js';
import { trackEvent } from '../../lib/analytics.js';
import { AnalyticsEvents } from '../../../types/AnalyticsEvents.js';
import { SharedQuestionsList } from '../organisms/SharedQuestionsList.js';

const AddQuestionPlaceholder = () => {
    return (
        <>
            <SharedQuestionsList existingQuestions={[]} />
            <div className={styles.container}>
                <SvgIcon className={styles.icon} viewBox="0 0 205 205">
                    <LightBulbPencilSVG />
                </SvgIcon>
                <h1 className={styles.h1}>Questions</h1>
                <p className={styles.p}>
                    Add a question and customize the criteria students will use to give
                    feedback.
                </p>
                <div className={styles.button_container}>
                    <Button
                        kind="primary"
                        to="/questions/wizard"
                        onClick={() => {
                            trackEvent(AnalyticsEvents.addQuestionButtonClicked, {
                                location: 'placeholder_page',
                            });
                        }}
                        className={styles.button}
                    >
                        Add New Question
                    </Button>
                    <Button
                        kind="secondary"
                        to="/dashboard/discover"
                        className={styles.button}
                        onClick={() => {
                            trackEvent(AnalyticsEvents.preCreatedQuestionButtonClicked, {
                                location: 'placeholder_page',
                            });
                        }}
                    >
                        Select from pre-created
                    </Button>
                </div>
            </div>
        </>
    );
};

export default AddQuestionPlaceholder;

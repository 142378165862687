import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import styles from 'styles/pages/ForgotPassword.scss';
import TextInput from '../atoms/TextInput.js';
import Button from '../atoms/Button.js';
import PasswordConfirmValidate from '../organisms/PasswordConfirmValidate.js';
import { isValidPassword } from '../_helpers/validatePassword.js';
import LogoTextLink from '../atoms/LogoTextLink.js';

const RememberPasswordFooter = () => (
    <span className={styles.remember_pw_footer}>
        <p>Remember your password?</p>
        <NavLink to="/sign-in">Sign in here</NavLink>
    </span>
);

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [currentStep, setCurrentStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (error) {
            setError('');
        }
        if (loading) {
            setLoading(false);
        }
    }, [currentStep]);

    const sendForgotPasswordEmail = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            await Auth.forgotPassword(email);
        } catch (err) {
            setError(err.message);
        }

        setCurrentStep(1);
    };

    const updatePassword = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            await Auth.forgotPasswordSubmit(email, code, password);
        } catch (err) {
            setError(err.message);
        }
        setCurrentStep(2);
    };

    const formSteps = [
        <>
            <p className={styles.caption}>
                <b>Step 1:</b> We&apos;ll send a verification code to your email if a user
                exists
            </p>
            <form className={styles.form} onSubmit={sendForgotPasswordEmail}>
                <TextInput
                    className={styles.input}
                    type="email"
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    autoFocus
                />
                <Button
                    type="submit"
                    kind="primary"
                    sx={{ mt: 35 }}
                    disabled={!email}
                    loading={loading}
                >
                    Send Verification Code
                </Button>
                {error && (
                    <FormHelperText error className={styles.error_text}>
                        {error}
                    </FormHelperText>
                )}
            </form>
            <RememberPasswordFooter />
        </>,
        <>
            <p className={styles.caption}>
                <b>Step 2:</b> Enter verification code and update password for{' '}
                <b>{email}</b>
            </p>
            <form className={styles.form} onSubmit={updatePassword}>
                <TextInput
                    className={styles.input}
                    label="Verification Code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    autoFocus
                />
                <PasswordConfirmValidate
                    containerClassName={styles.password_confirm_validate}
                    password={password}
                    onChangePassword={setPassword}
                    confirmedPassword={confirmedPassword}
                    onChangeConfirmedPassword={setConfirmedPassword}
                />
                <Button
                    type="submit"
                    kind="primary"
                    disabled={
                        !code ||
                        !email ||
                        password !== confirmedPassword ||
                        !isValidPassword(password)
                    }
                    loading={loading}
                >
                    Update Password
                </Button>
                {error && (
                    <FormHelperText error className={styles.error_text}>
                        {error}
                    </FormHelperText>
                )}
            </form>
            <RememberPasswordFooter />
        </>,
        <span className={styles.update_success_container}>
            Password successfully updated!{' '}
            <NavLink to="/sign-in">Back to sign in</NavLink>
        </span>,
    ];

    return (
        <div className={styles.container}>
            <LogoTextLink linkClassName={styles.logo_text_link_link} />
            <Card className={styles.card}>
                <Typography className={styles.h1}>Forgot your password?</Typography>

                {formSteps[currentStep]}
            </Card>
        </div>
    );
};

export default ForgotPassword;

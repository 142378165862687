export const enum Colors {
    blue = '#222362',
    bluePurple = '#3A3CAD',
    lightBluePurple = '#6B6DC2',
    periwinkle = '#8C8CFF',
    lightBlue = 'rgb(0, 95, 204)',
    turqoise = '#97FFED',
    green = '#2e8540',
    darkGreen = '#00725E',
    lightGreen = '#00BD9D',
    tanGray = '#D7CDCC',
    darkGray = '#6a6665',
    lightGray = '#F6F8FA',
    gray = '#DFE2E6',
    silver = '#F3F3F3',
    white = '#FFFFFF',
    black = '#000000',
    red = '#981b1e',
    gold = '#FFCF3B',
    ruby = '#D81E5B',
    darkRuby = '#AB1848',
    bronze = '#E9AD74',
}

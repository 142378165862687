import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import Card from '@mui/material/Card';
import FormHelperText from '@mui/material/FormHelperText';
import styles from 'styles/pages/VerifyEmail.scss';
import TextInput from '../atoms/TextInput.js';
import Button from '../atoms/Button.js';
import LogoTextLink from '../atoms/LogoTextLink.js';

const VerifySignUp = () => {
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname;
    const email = location.state?.email;

    const verifyAccount = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            await Auth.verifyCurrentUserAttributeSubmit('email', code);

            navigate(from, { replace: true });
        } catch (err) {
            setLoading(false);
            setError(err.message);
        }
    };

    const resendSignUpVerification = async () => {
        try {
            await Auth.resendSignUp(email);
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className={styles.container}>
            <LogoTextLink linkClassName={styles.logo_text_link_link} />
            <Card className={styles.card}>
                <h1 className={styles.h1}>Verify Your Email Address</h1>
                <p className={styles.caption}>
                    A verification code has been sent to <b>{`${email}`}</b>
                </p>
                <p className={styles.caption}>
                    Please check your inbox and enter the verification code below to
                    verify your email address. The code will expire in 24 hours.
                </p>
                <p className={styles.caption}>
                    Check your spam folder if you don&apos;t see the email in your inbox.
                </p>
                <form className={styles.form}>
                    <TextInput
                        label="Verification Code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        required
                        autoFocus
                    />
                    <Button
                        kind="secondary"
                        className={styles.verification_button}
                        onClick={resendSignUpVerification}
                    >
                        Send new verification code
                    </Button>
                    <Button
                        type="submit"
                        kind="primary"
                        onClick={verifyAccount}
                        disabled={!code}
                        loading={loading}
                    >
                        Submit
                    </Button>
                    {error && (
                        <FormHelperText error sx={{ textAlign: 'center' }}>
                            {error}
                        </FormHelperText>
                    )}
                </form>
            </Card>
        </div>
    );
};

export default VerifySignUp;

import React from 'react';
import { Outlet } from 'react-router-dom';
import { useActivityRoundMonitor } from '../../../hooks/useActivityRoundMonitor/index.js';
import { useRemovedParticipantMonitor } from '../../../hooks/socketEvents/useRemovedParticipantMonitor.js';

export const ActivityMonitor = () => {
    useActivityRoundMonitor();
    useRemovedParticipantMonitor();

    return <Outlet />;
};

import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import styles from 'styles/templates/Sidebar/Sidebar.scss';
import { RoomContext } from '../../context/Room.js';
import Hamburger from '../../icons/Hamburger.js';
import QuestionMarkChatBubble from '../../icons/QuestionMarkChatBubble.js';
import InfoBubble from '../../icons/InfoBubble.js';
import LogoTextLink from '../../atoms/LogoTextLink.js';
import { trackEvent } from '../../../lib/analytics.js';
import { AnalyticsEvents } from '../../../../types/AnalyticsEvents.js';
import QuestionMarkCircleChatBubble from '../../icons/QuestionMarkCircleChatBubble.js';
import Seedling from '../../icons/Seedling.js';
import { MyAccountMenu } from './MyAccountMenu.js';
import Stars from '../../icons/Stars.js';
import { useCreateStripeCheckoutSession } from '../../hooks/mutations/useCreateStripeCheckoutSession.js';
import {
    hasPremiumSubscription,
    hasTrialSubscription,
} from '../../../../shared/subscriptionStatusHelpers.js';
import { useCreateStripeCustomerPortalSession } from '../../hooks/mutations/useCreateStripeCustomerPortalSession.js';
import { useRemainingTrialDays } from '../../hooks/queries/useRemainingTrialDays.js';
import { useProfileImage } from '../../hooks/queries/useProfileImage.js';
import { ProfileImage } from '../../atoms/ProfileImage.js';
import { Tooltip } from '../../atoms/Tooltip.js';
import { TopNavLink } from './TopNavLink.js';
import Results from '../../icons/Results.js';
import Explore from '../../icons/Explore.js';

export interface SidebarProps {
    show?: boolean;
    onClickHambuger?: () => void;
}

const Sidebar = ({ show, onClickHambuger }: SidebarProps) => {
    const room = useContext(RoomContext);

    const [expanded, setExpanded] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [profileImage, setProfileImage] = useState('');
    const open = Boolean(anchorEl);

    const location = useLocation();

    const createStripeCheckoutSession = useCreateStripeCheckoutSession();
    const createStripeCustomerPortalSession = useCreateStripeCustomerPortalSession();

    const { data: profileImageData } = useProfileImage();
    const { data } = useRemainingTrialDays();

    const { remainingTrialDays = 0 } = data || {};

    useEffect(() => {
        /** This is janky, but the sidebar can be expanded/ collapsed via the hamburger button on the sidebar or via the hamburger button in the header (for mobile responsivenes we hide the header fully when it's collapsed on mobile) */
        if (show) {
            setExpanded(true);
        }
    }, [show]);

    useEffect(() => {
        if (!profileImageData) return;

        const profileImageFromS3 = new File([profileImageData], 'profile-image', {
            type: profileImageData.type,
        });

        const reader = new FileReader();
        reader.readAsDataURL(profileImageFromS3);
        reader.onloadend = () => {
            setProfileImage(reader.result as string);
        };
    }, [profileImageData?.size]);

    const handleExpandSidebar = () => {
        setExpanded((prev) => !prev);
        onClickHambuger();
    };

    const handleClickTryPremium = () => {
        trackEvent(AnalyticsEvents.sidebarTryPremiumButtonClicked);
        createStripeCheckoutSession.mutate({
            planType: 'annual',
        });
    };

    const handleClickUpgradeNow = () => {
        trackEvent(AnalyticsEvents.sidebarUpgradeButtonClicked);
        createStripeCustomerPortalSession.mutate();
    };

    const isPremium = hasPremiumSubscription(room);
    const isTrial = hasTrialSubscription(room);

    return (
        <>
            <div
                id="sidebar"
                className={cn([
                    styles.container,
                    {
                        [styles.expanded]: expanded,
                        [styles.collapsed]: !expanded,
                    },
                ])}
            >
                <span
                    className={cn(styles.sidebar_header, {
                        [styles.collapsed]: !expanded,
                    })}
                >
                    <IconButton
                        className={styles.hamburger_button}
                        onClick={handleExpandSidebar}
                    >
                        <Hamburger className={styles.hamburger_icon} />
                    </IconButton>

                    <span>
                        <LogoTextLink
                            linkClassName={cn(styles.logo_text_link_link, {
                                [styles.hidden]: !expanded,
                            })}
                            iconClassName={styles.logo_text_link_icon}
                        />
                    </span>
                </span>
                <div className={styles.links_container}>
                    <div>
                        <TopNavLink
                            text="Questions"
                            link="/dashboard/questions"
                            icon={QuestionMarkChatBubble}
                            expanded={expanded}
                            showTooltip
                        />
                        <TopNavLink
                            text="Results"
                            link="/dashboard/results"
                            icon={Results}
                            expanded={expanded}
                            className={styles.results_tab}
                            showTooltip
                        />
                        <TopNavLink
                            text="Discover"
                            link="/dashboard/discover"
                            icon={Explore}
                            expanded={expanded}
                            showTooltip
                        />
                    </div>
                    <div className={styles.bottom_nav_items_container}>
                        <button
                            type="button"
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                            className={cn([
                                styles.my_account_button,
                                styles.nav_link_content_container,
                                {
                                    [styles.nav_link_active]:
                                        location.pathname.includes('/dashboard/account'),
                                },
                            ])}
                        >
                            <div className={styles.my_account_icon_container}>
                                <ProfileImage
                                    src={profileImage}
                                    accountDefaultClassName={cn([
                                        styles.my_account_icon,
                                        {
                                            [styles.my_account_icon_premium]: isPremium,
                                        },
                                    ])}
                                    profileImageClassName={cn([
                                        styles.profile_image,
                                        {
                                            [styles.my_account_icon_premium]: isPremium,
                                        },
                                    ])}
                                />
                                {isPremium && (
                                    <Stars className={styles.my_account_icon_stars} />
                                )}
                            </div>
                            <div
                                className={cn([
                                    styles.my_account_text_container,
                                    {
                                        [styles.hidden]: !expanded,
                                    },
                                ])}
                            >
                                <p className={styles.my_account_text}>My Account</p>
                                {isPremium && (
                                    <p className={styles.premium_text}>Premium</p>
                                )}
                            </div>
                        </button>
                        <div className={styles.resources_container}>
                            <Tooltip
                                expanded={expanded}
                                tooltipTitle="Getting Started"
                                tooltipArrowClassName={styles.tooltip_arrow}
                                tooltipClassName={styles.tooltip}
                                tooltipParent={
                                    <Link
                                        href="https://myshortanswer.com/teacher-resources/"
                                        className={styles.resources_link}
                                        target="_blank"
                                        rel="noreferrer"
                                        underline="hover"
                                        onClick={() =>
                                            trackEvent(
                                                AnalyticsEvents.resourcesLinkClicked,
                                            )
                                        }
                                    >
                                        <InfoBubble
                                            className={styles.nav_sub_link_icon}
                                        />
                                        <p
                                            className={cn([
                                                styles.nav_sub_link_text,
                                                {
                                                    [styles.hidden]: !expanded,
                                                },
                                            ])}
                                        >
                                            Getting Started
                                        </p>
                                    </Link>
                                }
                            />
                            <Tooltip
                                expanded={expanded}
                                tooltipTitle="Community"
                                tooltipArrowClassName={styles.tooltip_arrow}
                                tooltipClassName={styles.tooltip}
                                tooltipParent={
                                    <Link
                                        href="https://www.facebook.com/groups/593089402256533"
                                        className={styles.resources_link}
                                        target="_blank"
                                        rel="noreferrer"
                                        underline="hover"
                                        onClick={() =>
                                            trackEvent(
                                                AnalyticsEvents.communityLinkClicked,
                                            )
                                        }
                                    >
                                        <Seedling className={styles.nav_sub_link_icon} />
                                        <p
                                            className={cn([
                                                styles.nav_sub_link_text,
                                                {
                                                    [styles.hidden]: !expanded,
                                                },
                                            ])}
                                        >
                                            Community
                                        </p>
                                    </Link>
                                }
                            />
                            <Tooltip
                                expanded={expanded}
                                tooltipTitle="Support"
                                tooltipArrowClassName={styles.tooltip_arrow}
                                tooltipClassName={styles.tooltip}
                                tooltipParent={
                                    <Link
                                        href="https://myshortanswer.com/customer-support/"
                                        className={styles.resources_link}
                                        target="_blank"
                                        rel="noreferrer"
                                        underline="hover"
                                        onClick={() =>
                                            trackEvent(AnalyticsEvents.supportLinkClicked)
                                        }
                                    >
                                        <QuestionMarkCircleChatBubble
                                            className={styles.nav_sub_link_icon}
                                        />
                                        <p
                                            className={cn([
                                                styles.nav_sub_link_text,
                                                {
                                                    [styles.hidden]: !expanded,
                                                },
                                            ])}
                                        >
                                            Support
                                        </p>
                                    </Link>
                                }
                            />
                        </div>
                        {!isPremium && (
                            <>
                                {expanded && (
                                    <Button
                                        startIcon={<Stars />}
                                        variant="contained"
                                        className={styles.try_premium_button}
                                        onClick={handleClickTryPremium}
                                    >
                                        Upgrade to Premium
                                    </Button>
                                )}
                                {!expanded && (
                                    <div className={styles.upgrade_button_icon_container}>
                                        <IconButton
                                            className={styles.upgrade_button_icon}
                                            onClick={handleClickTryPremium}
                                        >
                                            <Stars />
                                        </IconButton>
                                    </div>
                                )}
                            </>
                        )}
                        {isTrial && (
                            <>
                                {expanded && (
                                    <div className={styles.upgrade_button_container}>
                                        <p className={styles.free_trial_text}>
                                            <span
                                                className={
                                                    styles.free_trial_remaining_days
                                                }
                                            >
                                                {remainingTrialDays} days
                                            </span>{' '}
                                            left in free trial
                                        </p>
                                        <Button
                                            startIcon={<Stars />}
                                            variant="contained"
                                            className={styles.upgrade_button}
                                            onClick={handleClickUpgradeNow}
                                        >
                                            Upgrade Now
                                        </Button>
                                    </div>
                                )}
                                {!expanded && (
                                    <div className={styles.upgrade_button_icon_container}>
                                        <IconButton
                                            className={styles.upgrade_button_icon}
                                            onClick={handleClickUpgradeNow}
                                        >
                                            <Stars />
                                        </IconButton>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <MyAccountMenu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
            />
        </>
    );
};

export default Sidebar;

import React from 'react';
import Card from '@mui/material/Card';
import styles from 'styles/pages/MaintenanceMode.scss';
import LogoTextLink from '../atoms/LogoTextLink.js';

export const MaintenanceMode = () => {
    return (
        <div className={styles.container}>
            <LogoTextLink
                linkClassName={styles.logo_text_link_link}
                iconClassName={styles.logo_text_link_icon}
            />
            <Card className={styles.card}>
                <div className={styles.content_container}>
                    <div className={styles.text_button_container}>
                        <h1 className={styles.h1}>
                            Scheduled Maintenance In Progress 🛠️
                        </h1>
                        <p className={styles.p}>
                            Sorry for the inconvenience, but we&rsquo;re making some
                            improvements to Short Answer. If you need to you can always{' '}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://myshortanswer.com/customer-support/"
                            >
                                contact us
                            </a>
                            , otherwise we&rsquo;ll be back online in a couple hours.
                        </p>
                        <p className={styles.p}>&mdash; The Short Answer Team</p>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default MaintenanceMode;

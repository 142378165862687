import React from 'react';
import { captureException } from '@sentry/react';
import { Auth } from '@aws-amplify/auth';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import { NavLink, useNavigate } from 'react-router-dom';
import styles from 'styles/templates/Sidebar/MyAccountMenu.scss';
import Gear from '../../icons/Gear.js';
import RightArrowBox from '../../icons/RightArrowBox.js';
import { trackEvent } from '../../../lib/analytics.js';
import { AnalyticsEvents } from '../../../../types/AnalyticsEvents.js';
import Stars from '../../icons/Stars.js';
import { clearLocalSessionStorage } from '../../_helpers/clearLocalSessionStorage.js';

export interface MyAccountMenuProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
}

export const MyAccountMenu = ({ open, anchorEl, onClose }: MyAccountMenuProps) => {
    const navigate = useNavigate();

    const signOut = async () => {
        try {
            trackEvent(AnalyticsEvents.signedOut);
            await Auth.signOut();
            clearLocalSessionStorage();
            navigate('/sign-in');
        } catch (error) {
            captureException(error);
        }
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <MenuList className={styles.menu}>
                <MenuItem
                    component={NavLink}
                    to="/dashboard/account/settings"
                    onClick={onClose}
                >
                    <ListItemIcon>
                        <Gear className={styles.menu_icon} />
                    </ListItemIcon>
                    <ListItemText className={styles.menu_text}>Settings</ListItemText>
                </MenuItem>
                <MenuItem
                    component={NavLink}
                    to="/dashboard/account/plan"
                    onClick={onClose}
                >
                    <ListItemIcon>
                        <Stars className={styles.menu_icon_stars} />
                    </ListItemIcon>
                    <ListItemText className={styles.menu_text}>Plan</ListItemText>
                </MenuItem>
                <MenuItem
                    component={Button}
                    className={styles.menu_sign_out}
                    onClick={signOut}
                >
                    <ListItemIcon>
                        <RightArrowBox className={styles.menu_icon} />
                    </ListItemIcon>
                    <ListItemText className={styles.menu_text}>Sign out</ListItemText>
                </MenuItem>
            </MenuList>
        </Menu>
    );
};

export default MyAccountMenu;

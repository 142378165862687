import React, { useState, FormEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FormHelperText from '@mui/material/FormHelperText';
import Card from '@mui/material/Card';
import styles from 'styles/templates/UpdateEmailForm.scss';
import TextInput from '../atoms/TextInput.js';
import Button from '../atoms/Button.js';
import { User } from '../../../types/User.js';

export interface UpdateEmailFormProps {
    attributes: User['attributes'];
    onFormSubmit: (updatedAttributes: User['attributes']) => Promise<void>;
}

const UpdateEmailForm = ({ attributes, onFormSubmit }: UpdateEmailFormProps) => {
    const [email, setEmail] = useState('');
    const [confirmedEmail, setConfirmedEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        try {
            await onFormSubmit({ email });
            navigate('/verify-email', {
                state: { from: location, email },
                replace: true,
            });
        } catch (err) {
            setError(`Error updating email: ${err.message}`);
        }

        setLoading(false);
    };

    return (
        <>
            <h2 className={styles.h2}>Email</h2>
            <p>
                Your current email address is set to{' '}
                <strong>{attributes?.email ?? ''}</strong>
            </p>
            <Card className={styles.card}>
                <form onSubmit={handleSubmit}>
                    <div className={styles.input_container}>
                        <TextInput
                            type="email"
                            sx={{ flex: 1, mr: 20 }}
                            value={email}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            label="New Email"
                            variant="outlined"
                            className={styles.input}
                        />
                        <TextInput
                            type="email"
                            sx={{ flex: 1, ml: 20 }}
                            value={confirmedEmail}
                            required
                            onChange={(e) => setConfirmedEmail(e.target.value)}
                            label="Confirm New Email"
                            variant="outlined"
                            className={styles.input}
                        />
                    </div>
                    <div className={styles.button_container}>
                        {error && (
                            <FormHelperText className={styles.error_text} error>
                                {error}
                            </FormHelperText>
                        )}
                        <Button
                            disabled={
                                email === '' ||
                                confirmedEmail === '' ||
                                email !== confirmedEmail
                            }
                            type="submit"
                            kind="primary"
                            loading={loading}
                        >
                            Change Email
                        </Button>
                    </div>
                </form>
            </Card>
        </>
    );
};

export default UpdateEmailForm;

import React, { useState, FormEvent, useContext } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import styles from 'styles/pages/Join.scss';
import Button from '../atoms/Button.js';
import TextInput from '../atoms/TextInput.js';
import LogoTextLink from '../atoms/LogoTextLink.js';
import { SocketContext } from '../context/Socket.js';
import { SESSION } from '../../../constants/channels.js';
import { SocketResponse } from '../../../types/Socket.js';

const Join = () => {
    const [joinCode, setJoinCode] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const socket = useContext(SocketContext);

    const navigate = useNavigate();

    const handleJoinRoom = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        socket.emit(SESSION.CHECK, { joinCode }, (data: SocketResponse) => {
            if (data.success) {
                navigate(`/room/${joinCode}/participant/enter-name`);
            } else {
                setLoading(false);
                setError(data.error);
            }
        });
    };

    return (
        <div className={styles.container}>
            <LogoTextLink linkClassName={styles.logo_text_link_link} />
            <Card className={styles.card}>
                <h1 className={styles.h1}>Enter Join Code</h1>

                <form className={styles.form} onSubmit={handleJoinRoom}>
                    <TextInput
                        className={styles.input}
                        type="text"
                        label="Join Code"
                        value={joinCode}
                        onChange={(e) => setJoinCode(e.target.value.toUpperCase())}
                        required
                        autoFocus
                    />
                    <Button
                        type="submit"
                        kind="primary"
                        disabled={!joinCode}
                        loading={loading}
                    >
                        Continue
                    </Button>
                    {error && (
                        <FormHelperText error className={styles.error_text}>
                            {error}
                        </FormHelperText>
                    )}
                </form>
                <Typography className={styles.tos_text}>
                    By entering this code, you confirm that you and/or the teacher who
                    gave you this code have the ability to consent to the{' '}
                    <a
                        href="https://myshortAnswer.com/terms-of-service/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        terms of service
                    </a>{' '}
                    and{' '}
                    <a
                        href="https://myshortanswer.com/privacy-policy/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        privacy policy
                    </a>{' '}
                    and give that consent.
                </Typography>
                <span className={styles.caption_link}>
                    <p>Not a student?</p>
                    <NavLink to="/sign-in">Teacher sign in</NavLink>
                </span>
            </Card>
        </div>
    );
};

export default Join;

import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from 'styles/pages/room/participant/AddResponse/SubmissionConfirmationWaitScreen.scss';
import StudentVerticalThreeDotMenu from '../../../../templates/Footer/StudentVerticalThreeDotMenu.js';
import { AvatarBuilder } from '../../../../templates/AvatarBuilder.js';
import DisplayResponse from '../../../../atoms/DisplayResponse.js';
import { Breadcrumbs } from '../../../../molecules/Breadcrumbs.js';
import { useParticipantResponse } from '../../../../hooks/queries/useParticipantResponse.js';
import { ParticipantContext } from '../../../../context/Participant.js';
import { SessionContext } from '../../../../context/Session.js';
import AI from '../../../../icons/AI.js';

const content = {
    'quick-write': (
        <>
            <p>Your response will soon receive AI feedback. Keep in mind...</p>

            <h2 className={styles.h2}>
                <AI /> AI in the loop, humans in charge.
            </h2>

            <ul className={styles.ul}>
                <li key={1}>
                    <h3 className={styles.h3}>Do you agree?</h3>
                    <p>
                        AI’s can sometimes make things up or be incorrect. Think for
                        yourself! Double check the info AI gives you with your teacher,
                        textbook, or other reputable sources.
                    </p>
                </li>
                <li key={2}>
                    <h3 className={styles.h3}>Need guidance?</h3>
                    <p>Ask your teacher! Or another classmate.</p>
                </li>
            </ul>
        </>
    ),
    default: (
        <>
            <div className={styles.instructions_container}>
                <p>
                    In a moment, two of your classmates&apos; responses will appear on
                    your screen. You&apos;ll be asked to compare them. Here are some best
                    practices to keep in mind:
                </p>
                <ol>
                    <li key={1}>Be respectful.</li>
                    <li key={2}>Read each response fully before providing feedback.</li>
                    <li key={3}>
                        Click the &quot;neither&quot; arrow on the top right if you
                        don&apos;t think either meets the criteria.
                    </li>
                </ol>
                <p>
                    Want to know more about why you compare responses in Short Answer?
                    Check out{' '}
                    <a
                        className={styles.a}
                        href="https://myshortanswer.com/whats-with-all-the-comparing-in-short-answer/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        our post
                    </a>{' '}
                    about it!
                </p>
            </div>
            <p className={styles.waiting_text}>waiting for others to submit...</p>
        </>
    ),
};

const SubmissionConfirmationWaitScreen = () => {
    const participant = useContext(ParticipantContext);
    const location = useLocation();
    const session = useContext(SessionContext);

    const [showAvatarBuilder, setShowAvatarBuilder] = useState<boolean>(
        location?.state?.showAvatarBuilder ?? true,
    );

    const { data, isLoading } = useParticipantResponse({ participant });

    const response = data?.response;

    const contentKey =
        session?.activity_slug === 'quick-write' ? 'quick-write' : 'default';

    return (
        <div className={styles.container}>
            <Breadcrumbs
                activeIndicatorColor="#FFB64C"
                className={styles.breadcrumbs}
                stepContainerClassName={styles.columns_container}
                steps={[
                    <div key="step-1" className={styles.left_side_container}>
                        <DisplayResponse
                            response={response?.[0]?.response}
                            title="Your response:"
                            className={styles.display_response}
                            isLoading={isLoading}
                        />
                    </div>,
                    <div key="step-2" className={styles.right_side}>
                        <div className={styles.threedot}>
                            <StudentVerticalThreeDotMenu />
                        </div>
                        {showAvatarBuilder && (
                            <div className={styles.avatar_container}>
                                <AvatarBuilder
                                    onClickDone={() => setShowAvatarBuilder(false)}
                                />
                                <p className={styles.waiting_text}>
                                    waiting for others to submit...
                                </p>
                            </div>
                        )}
                        {!showAvatarBuilder && (
                            <div className={styles.right_side_wrapper}>
                                <h1 className={styles.h1}>Great submission!</h1>
                                {content[contentKey]}
                            </div>
                        )}
                    </div>,
                ]}
            />
        </div>
    );
};

export default SubmissionConfirmationWaitScreen;

import React, { useContext, useEffect } from 'react';
import confetti from 'canvas-confetti';
import { useNavigate } from 'react-router-dom';
import styles from 'styles/pages/room/participant/AddResponse/SubmissionConfirmation.scss';
import StarPerson from '../../../../icons/StarPerson.js';
import Button from '../../../../atoms/Button.js';
import StudentVerticalThreeDotMenu from '../../../../templates/Footer/StudentVerticalThreeDotMenu.js';
import { showFullPageConfetti } from '../../../../_helpers/showFullPageConfetti.js';
import { SessionContext } from '../../../../context/Session.js';

export const SubmissionConfirmation = () => {
    const session = useContext(SessionContext);
    const navigate = useNavigate();

    useEffect(() => {
        showFullPageConfetti();
        return () => {
            confetti.reset();
        };
    }, []);

    const h1Content = 'Great submission! Want to customize your avatar while we wait?';

    const submissionConfirmationWaitScreenRoute = `/room/${session?.join_code}/participant/add-response/submitted`;

    return (
        <>
            <div className={styles.three_dot}>
                <StudentVerticalThreeDotMenu />
            </div>
            <div className={styles.container}>
                <div className={styles.star_person_container}>
                    <StarPerson className={styles.star_person} />
                    <h2 className={styles.h2}>Your response looks great!</h2>
                </div>
                <h1 className={styles.h1}>{h1Content}</h1>

                <div className={styles.yes_no_button_container}>
                    <Button
                        kind="primary"
                        className={styles.no_button}
                        onClick={() => {
                            confetti.reset();
                            navigate(submissionConfirmationWaitScreenRoute, {
                                replace: true,
                                state: { showAvatarBuilder: false },
                            });
                        }}
                    >
                        No
                    </Button>
                    <Button
                        kind="primary"
                        className={styles.yes_button}
                        onClick={() => {
                            navigate(submissionConfirmationWaitScreenRoute, {
                                replace: true,
                                state: { showAvatarBuilder: true },
                            });
                        }}
                    >
                        Yes!
                    </Button>
                </div>
            </div>
        </>
    );
};

export default SubmissionConfirmation;

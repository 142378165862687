import React from 'react';
import styles from 'styles/pages/NotFound.scss';
import Button from '../atoms/Button.js';
import Avatar, { AvatarProps } from '../organisms/Avatar/index.js';

const avatar: AvatarProps = {
    id: '100',
    avatarSpecs: {
        bgColor: 'transparent',
        shapeColor: 'red',
        shape: 'circle',
        face: 'face-13',
    },
    width: 300,
    showBase: false,
    className: styles.avatar_100,
};

const NotFound = () => {
    return (
        <div className={styles.notFound_container}>
            <div>
                <h2>Looks like you are in the wrong place!</h2>
                <h1>
                    <span data-alt="4">4</span>
                    <span data-alt="0">
                        <Avatar key={avatar.id} {...avatar} />
                    </span>
                    <span data-alt="4">4</span>
                </h1>
                <h3>
                    The page you are looking for might <br />
                    have been removed or is not avaliable.
                </h3>
                <Button kind="primary" href="/" className={styles.home_button}>
                    Go to Homepage
                </Button>
            </div>
        </div>
    );
};

export default NotFound;

import React, { FormEvent, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import styles from 'styles/pages/SignIn.scss';
import TextInput from '../atoms/TextInput.js';
import Button from '../atoms/Button.js';
import LogoTextLink from '../atoms/LogoTextLink.js';
import { AnalyticsEvents } from '../../../types/AnalyticsEvents.js';
import { trackEvent } from '../../lib/analytics.js';
import { GoogleButton } from '../molecules/GoogleButton.js';

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const signIn = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            setLoading(true);
            await Auth.signIn(email, password);

            navigate('/dashboard/questions', { replace: true });
        } catch (err) {
            if (err.code === 'UserNotConfirmedException') {
                navigate('/verify-sign-up', {
                    state: { email },
                    replace: true,
                });
            }

            setLoading(false);
            setError(err.message);
        }
    };

    const continueWithGoogle = () => {
        trackEvent(AnalyticsEvents.signedIn);
        Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
        });
    };

    return (
        <div className={styles.container}>
            <LogoTextLink linkClassName={styles.logo_text_link_link} />
            <Card className={styles.card}>
                <h1 className={styles.h1}>Sign In</h1>
                <p className={styles.caption}>Welcome back to Short Answer!</p>

                <div className={styles.google_button_container}>
                    <GoogleButton onClick={continueWithGoogle} />
                </div>

                <Divider className={styles.divider}>
                    <div>or</div>
                </Divider>

                <form className={styles.form} onSubmit={signIn}>
                    <TextInput
                        className={styles.input}
                        type="email"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoFocus
                        required
                    />
                    <TextInput
                        className={styles.input}
                        type="password"
                        label="Password"
                        value={password}
                        required
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <NavLink
                        className={styles.forgot_password_link}
                        to="/forgot-password"
                    >
                        Forgot password?
                    </NavLink>
                    <Button
                        type="submit"
                        kind="primary"
                        disabled={!email || !password}
                        loading={loading}
                        onClick={() => trackEvent(AnalyticsEvents.signedIn)}
                    >
                        Sign In
                    </Button>
                    {error && (
                        <FormHelperText error className={styles.error_text}>
                            {error}
                        </FormHelperText>
                    )}
                </form>

                <span className={styles.caption_link_container}>
                    <span className={styles.caption_link}>
                        <p>New to Short Answer?</p>
                        <NavLink to="/sign-up">Sign up here</NavLink>
                    </span>
                    <span className={styles.caption_link}>
                        <p>Not a teacher?</p>
                        <NavLink to="/join">Student sign in</NavLink>
                    </span>
                </span>
            </Card>
        </div>
    );
};

export default SignIn;

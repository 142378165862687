import { Auth } from '@aws-amplify/auth';
import Card from '@mui/material/Card';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { captureException } from '@sentry/react';
import { Storage } from 'aws-amplify';
import cn from 'classnames';
import React, { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from 'styles/templates/DeleteAccount.scss';
import { deleteAccountReasons } from '../../../constants/deleteAccountReasons.js';
import { AnalyticsEvents } from '../../../types/AnalyticsEvents.js';
import { User } from '../../../types/User.js';
import { trackEvent } from '../../lib/analytics.js';
import Button from '../atoms/Button.js';
import { Dialog } from '../atoms/Dialog.js';
import TextInput from '../atoms/TextInput.js';
import { useDeleteMailchimpContact } from '../hooks/mutations/useDeleteMailchimpContact.js';
import useDeleteRoom from '../hooks/mutations/useDeleteRoom.js';
import Warning from '../icons/Warning.js';
import { clearLocalSessionStorage } from '../_helpers/clearLocalSessionStorage.js';

const DeleteAccount = ({ user }: { user?: User }) => {
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [reason, setReason] = useState('--Select--');
    const [email, setEmail] = useState('');
    const [errorText, setErrorText] = useState('');
    const [loading, setLoading] = useState(false);

    const deleteRoom = useDeleteRoom();
    const deleteMailchimpContact = useDeleteMailchimpContact();

    const onClose = () => {
        setShowModal(false);
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setLoading(true);

        try {
            await deleteMailchimpContact.mutateAsync({ email });
            await Storage.remove('profile-image', { level: 'private' });
            await deleteRoom.mutateAsync();
            await Auth.deleteUser();

            trackEvent(AnalyticsEvents.accountDeleted, {
                reason,
            });

            await Auth.signOut();
            clearLocalSessionStorage();
            navigate('/sign-up', { replace: true });
        } catch (error) {
            captureException(error);
            setErrorText(error.message);
            setLoading(false);
        }
    };

    const promptUser = () => {
        setShowModal(true);
    };

    return (
        <div className={styles.delete_account_container}>
            <span className={styles.header}>
                <Warning fontSize="small" className={styles.warning_icon} />
                <h2 className={styles.h2}>Delete Account</h2>
            </span>
            <Card className={cn([styles.card, styles.description_container])}>
                <p className={styles.description}>
                    Deleting your Short Answer account is permanent. All content from this
                    account will not be recoverable.
                </p>
                <span className={styles.button_container}>
                    <Button
                        kind="primary"
                        onClick={() => {
                            promptUser();
                        }}
                    >
                        Delete Account
                    </Button>
                </span>
            </Card>
            <Dialog open={showModal} onClose={onClose}>
                <DialogTitle className={styles.header}>
                    <Warning fontSize="medium" className={styles.warning_icon} />
                    <span className={styles.h2}>Delete Account</span>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <p className={styles.p}>
                            Delete account associated with{' '}
                            <strong>{user?.attributes?.email ?? ''}</strong>
                        </p>
                        <List className={styles.list}>
                            <h3 className={styles.delete_account_modal_header}>
                                By deleting your account:
                            </h3>
                            <ListItem className={styles.list_item}>
                                Your content will be permanently deleted (all questions,
                                classess, etc.)
                            </ListItem>
                            <ListItem className={styles.list_item}>
                                Your email address, name, profile photo, bio, and social
                                profiles will be deleted.
                            </ListItem>
                        </List>
                        <InputLabel
                            id="delete-email"
                            className={styles.delete_account_modal_header}
                        >
                            Enter your account email address:
                        </InputLabel>
                        <TextInput
                            className={styles.delete_account_input}
                            id="delete-email"
                            placeholder="Email Address"
                            type="email"
                            required
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <InputLabel
                            id="reason-for-delete"
                            className={styles.delete_account_modal_header}
                        >
                            Please tell us why you are leaving:
                        </InputLabel>
                        <Select
                            className={styles.delete_account_input}
                            id="reason-for-delete"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        >
                            {['--Select--', ...deleteAccountReasons].map((opt) => (
                                <MenuItem key={opt} value={opt}>
                                    {opt}
                                </MenuItem>
                            ))}
                        </Select>
                        <div className={styles.delete_account_button_container}>
                            <Button
                                className={cn([
                                    styles.button,
                                    styles.delete_account_button,
                                ])}
                                kind="text"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={styles.button}
                                type="submit"
                                kind="primary"
                                disabled={email !== user?.attributes?.email}
                                loading={deleteRoom.isLoading || loading}
                            >
                                Delete Account
                            </Button>
                        </div>
                        <FormHelperText>{errorText}</FormHelperText>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default DeleteAccount;

import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Fuse from 'fuse.js';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import styles from 'styles/pages/dashboard/questions/Questions.scss';
import Button from '../../../atoms/Button.js';
import AddQuestionPlaceholder from '../../../templates/AddQuestionPlaceholder.js';
import { RoomContext } from '../../../context/Room.js';
import { trackEvent } from '../../../../lib/analytics.js';
import { AnalyticsEvents } from '../../../../../types/AnalyticsEvents.js';
import { useAllQuestions } from '../../../hooks/queries/useAllQuestions.js';
import { hasPremiumSubscription } from '../../../../../shared/subscriptionStatusHelpers.js';
import { PremiumSubscriptionModal } from '../../../organisms/PremiumSubscriptionModal.js';
import { useAllRoomTopics } from '../../../hooks/queries/useAllRoomTopics.js';
import SearchBar from '../../../atoms/SearchBar.js';
import { Topic } from '../../../../../types/Topic.js';
import QuestionsTable from '../../../organisms/QuestionsTable.js';
import { CtaButton } from '../../../organisms/CtaButton.js';
import Send from '../../../icons/Send.js';
import { SharedQuestionsList } from '../../../organisms/SharedQuestionsList.js';
import { SendTopicQuestionsModal } from '../../../molecules/modals/SendTopicQuestionsModal.js';
import VerticalThreeDotMenu from '../../../molecules/VerticalThreeDotMenu.js';
import TopicMenuList from '../../../molecules/TopicMenuList.js';

const Questions = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const alertMessage = location.state?.alertMessage;
    const checkoutSuccess = queryParams.get('success');

    const room = useContext(RoomContext);

    const { data, status } = useAllQuestions();
    const { data: topicsData } = useAllRoomTopics();

    const topics = topicsData?.topics ?? [];

    // Sort topics so that the most recently created topics are first
    const sortedTopics = topics.sort(
        (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );

    const [showPremiumModal, setShowPremiumModal] = useState(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [selectedTopic, setSelectedTopic] = useState<Topic | null>(null);
    const [message, setMessage] = useState(alertMessage);
    const [showSendTopicQuestionsModal, setShowSendTopicQuestionsModal] = useState(false);

    useEffect(() => {
        if (checkoutSuccess === 'true') {
            setShowPremiumModal(true);
        }
    }, []);

    useEffect(() => {
        const currentSelectedTopic = topics?.find(
            (topic) => selectedTopic?.id === topic?.id,
        );

        if (!currentSelectedTopic) {
            setSelectedTopic(null);
        }
    }, [topics]);

    if (data?.questions?.length === 0) {
        return <AddQuestionPlaceholder />;
    }

    const handleClickSendTopicQuestions = () => {
        setShowSendTopicQuestionsModal(true);
    };

    const handleClosePremiumModal = () => {
        setShowPremiumModal(false);

        // Remove the success query param from the URL
        navigate(location.pathname);
    };

    const isPremium = hasPremiumSubscription(room);

    const showMaxQuestionsError = data?.questions?.length >= 5 && !isPremium;

    // Start with all questions
    let questions = data?.questions ?? [];

    // Filter questions by topic
    if (selectedTopic) {
        questions = questions.filter(
            (question) => selectedTopic.id === question.topic_id,
        );
    }

    // @ts-ignore
    const fuse = new Fuse(questions ?? [], {
        keys: ['question'],
    });

    // Filter questions by search value
    const results = fuse.search(searchValue);

    if (searchValue !== '') {
        questions = results.map((result) => result.item);
    }

    return (
        <>
            <SharedQuestionsList existingQuestions={questions} />
            <div className={styles.container}>
                <div className={styles.header_container}>
                    <h1 className={styles.h1}>My Questions</h1>

                    <div className={styles.search_and_button_container}>
                        <SearchBar
                            className={styles.search_bar}
                            searchValue={searchValue}
                            onChange={(value) => setSearchValue(value)}
                        />
                        <CtaButton
                            className={styles.cta_button}
                            room={room}
                            questionCount={data?.questions?.length ?? 0}
                        />
                        <Button
                            to="/questions/wizard"
                            kind="primary"
                            disabled={showMaxQuestionsError}
                            onClick={() =>
                                trackEvent(AnalyticsEvents.addQuestionButtonClicked, {
                                    location: 'questions_page',
                                })
                            }
                            className={styles.add_button}
                        >
                            Create Question
                        </Button>
                    </div>
                </div>
                <div className={styles.tabs_container}>
                    <Tabs
                        value={selectedTopic?.id ?? null}
                        onChange={(_, update) => setSelectedTopic(update)}
                        className={styles.tabs}
                        scrollButtons
                        allowScrollButtonsMobile
                        variant="scrollable"
                    >
                        <Tab
                            key={0}
                            label="Recent"
                            value={null}
                            className={styles.tab}
                            onClick={() => setSelectedTopic(null)}
                        />
                        {sortedTopics?.length > 0 &&
                            sortedTopics.map((topic) => (
                                <Tab
                                    key={topic.id}
                                    label={
                                        <div className={styles.tab_container}>
                                            {topic.name}
                                            {selectedTopic?.id === topic?.id && (
                                                <VerticalThreeDotMenu
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    className={
                                                        styles.topic_menu_three_dot
                                                    }
                                                    menuList={
                                                        <TopicMenuList
                                                            selectedTopic={selectedTopic}
                                                            topicQuestions={questions}
                                                            onDelete={() =>
                                                                setSelectedTopic(null)
                                                            }
                                                        />
                                                    }
                                                />
                                            )}
                                        </div>
                                    }
                                    value={topic?.id}
                                    className={styles.tab}
                                    onClick={() => {
                                        setSelectedTopic(topic);
                                    }}
                                />
                            ))}
                    </Tabs>
                </div>
                <QuestionsTable
                    questions={questions}
                    loading={status === 'loading'}
                    topics={topics}
                />
                {selectedTopic && questions?.length > 0 && (
                    <div className={styles.send_link_wrapper}>
                        <Button
                            kind="text"
                            className={styles.send_link}
                            onClick={handleClickSendTopicQuestions}
                        >
                            <Send className={styles.send_icon_size} />
                            Share question set with other teachers
                        </Button>
                    </div>
                )}
            </div>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={!!message}
                onClose={() => {
                    setMessage(null);
                }}
                autoHideDuration={7000}
            >
                <Alert
                    severity="success"
                    onClose={() => {
                        setMessage(null);
                    }}
                >
                    {message}
                </Alert>
            </Snackbar>
            <PremiumSubscriptionModal
                show={showPremiumModal}
                onClose={handleClosePremiumModal}
            />
            <SendTopicQuestionsModal
                show={showSendTopicQuestionsModal}
                selectedTopic={selectedTopic}
                topicQuestions={questions}
                onClose={() => setShowSendTopicQuestionsModal(false)}
            />
        </>
    );
};

export default Questions;

import React, { FormEvent, MouseEvent, useContext, useState } from 'react';
import { captureException } from '@sentry/react';
import Card from '@mui/material/Card';
import FormHelperText from '@mui/material/FormHelperText';
import { useNavigate, useParams } from 'react-router-dom';
import styles from 'styles/pages/room/participant/EnterName.scss';
import TextInputGo from '../../../molecules/TextInputGo.js';
import { useAddParticipant } from '../../../hooks/mutations/useAddParticipant.js';
import { useUpdateParticipant } from '../../../hooks/mutations/useUpdateParticipant.js';
import {
    ParticipantContext,
    SetParticipantContext,
} from '../../../context/Participant.js';
import { Participant } from '../../../../../types/Participant.js';
import { BadWordsError } from '../../../atoms/BadWordsError.js';
import { SessionContext } from '../../../context/Session.js';
import useJoinSession from '../../../hooks/useJoinSession.js';
import { SESSION } from '../../../../../constants/channels.js';
import { SocketContext } from '../../../context/Socket.js';
import { SocketResponse } from '../../../../../types/Socket.js';

export const EnterName = () => {
    const socket = useContext(SocketContext);
    const session = useContext(SessionContext);
    const participant = useContext(ParticipantContext);
    const setParticipant = useContext(SetParticipantContext);

    const { joinCode } = useParams();

    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [error, setError] = useState('');

    const joinSession = useJoinSession();
    const addParticipant = useAddParticipant();
    const updateParticipant = useUpdateParticipant();

    const handleJoinSession = (response: { participant: Participant }) => {
        const onJoinRoomSuccess = () => {
            navigate(`/room/${joinCode}/participant/add-response`, { replace: true });
        };

        const onJoinRoomError = () => {
            setError('Error joining room. Please try again');
        };

        if (!response?.participant?.id) {
            captureException(
                'Error joining session, there was no participant in the response',
            );

            onJoinRoomError();

            return;
        }

        joinSession(
            {
                joinCode,
                participantId: response.participant.id,
            },
            onJoinRoomSuccess,
            onJoinRoomError,
        );
    };

    const handleSubmit = (
        e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>,
    ) => {
        e.preventDefault();

        if (!joinCode) {
            navigate('/join', { replace: true });
            return;
        }

        const addParticiantAndJoinSession = () => {
            // If the participant is already in the session, maybe they clicked the back button, just update their name
            if (participant?.session_id === session?.id) {
                updateParticipant.mutate(
                    {
                        updatedParticipant: {
                            ...participant,
                            name,
                        },
                    },
                    {
                        onSuccess: (response) => {
                            handleJoinSession(response);
                        },
                    },
                );
                return;
            }

            addParticipant.mutate(
                { session, name },
                {
                    onSuccess: (response) => {
                        setParticipant(response?.participant);

                        handleJoinSession(response);
                    },
                    onError: (err: Error) => {
                        setError(err.message);
                    },
                },
            );
        };

        socket.emit(SESSION.CHECK, { joinCode }, (data: SocketResponse) => {
            if (data.error) {
                setError(data.error);
            } else {
                addParticiantAndJoinSession();
            }
        });
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Card className={styles.card}>
                <h1 className={styles.h1}>Welcome to Short Answer!</h1>
                <TextInputGo
                    id="enter-name-input"
                    autoFocus
                    disabled={!name}
                    label="Enter your first name"
                    value={name}
                    onClick={handleSubmit}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                />
                {error && (
                    <FormHelperText error className={styles.error_text}>
                        <BadWordsError error={error} />
                    </FormHelperText>
                )}
            </Card>
        </form>
    );
};

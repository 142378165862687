import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import styles from 'styles/pages/dashboard/account/AccountSettings.scss';
import { User } from '../../../../../types/User.js';
import { UserEditableRoom } from '../../../../../types/Room.js';
import UpdateProfileForm from '../../../templates/UpdateProfileForm.js';
import UpdatePasswordForm from '../../../templates/UpdatePasswordForm.js';
import UpdateEmailForm from '../../../templates/UpdateEmailForm.js';
import DeleteAccount from '../../../templates/DeleteAccount.js';
import { useUpdateRoom } from '../../../hooks/mutations/useUpdateRoom.js';

const Settings = () => {
    const [user, setUser] = useState<User | null>(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const updateRoom = useUpdateRoom();

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((currentUser) => {
            setUser(currentUser);
        });
    }, []);

    const updateAttributes = async (updatedAttributes: User['attributes']) => {
        try {
            await Auth.updateUserAttributes(user, updatedAttributes);
            setShowSnackbar(true);
        } catch (error) {
            throw new Error(error);
        }
    };

    const updatePassword = async ({ oldPassword, newPassword }) => {
        try {
            await Auth.changePassword(user, oldPassword, newPassword);
            setShowSnackbar(true);
        } catch (error) {
            throw new Error(error);
        }
    };

    const updateProfileData = async (
        userAttributes: User['attributes'],
        teachingData: UserEditableRoom,
    ) => {
        try {
            await updateAttributes(userAttributes);
            await updateRoom.mutateAsync({
                room: teachingData,
            });
            setShowSnackbar(true);
        } catch (error) {
            throw new Error(error);
        }
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.h1}>Account Settings</h1>
            <span>
                <UpdateProfileForm
                    attributes={user?.attributes}
                    onFormSubmit={updateProfileData}
                />
                <UpdateEmailForm
                    attributes={user?.attributes}
                    onFormSubmit={updateAttributes}
                />
                <UpdatePasswordForm onFormSubmit={updatePassword} />
                <DeleteAccount user={user} />
            </span>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={showSnackbar}
                autoHideDuration={3000}
                onClose={() => setShowSnackbar(false)}
            >
                <Alert onClose={() => setShowSnackbar(false)} severity="success">
                    Account Settings Updated!
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Settings;

import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import cn from 'classnames';
import styles from 'styles/pages/dashboard/Dashboard.scss';
import Sidebar from '../../templates/Sidebar/Sidebar.js';
import { RoomContext } from '../../context/Room.js';
import { WelcomeModal } from '../../molecules/modals/WelcomeModal.js';
import { hasPremiumSubscription } from '../../../../shared/subscriptionStatusHelpers.js';
import DashboardMessage from '../../organisms/DashboardMessage.js';
import { assetsUrlPrefix } from '../../../../constants/assetsUrlPrefix.js';
import { ProfileImage } from '../../atoms/ProfileImage.js';
import OnboardingOverlay from '../../atoms/OnboardingOverlay.js';

const Dashboard = () => {
    const room = useContext(RoomContext);

    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const [sidebarExtended, setSidebarExtended] = useState(false);
    const [showContactUsPrompt, setShowContactUsPrompt] = useState(false);
    const [showActivityCountPrompt, setShowActivityCountPrompt] = useState(false);
    const [showOnboardingOverlay, setShowOnboardingOverlay] = useState(false);

    const isPremium = hasPremiumSubscription(room);

    const handleExtendCollapseSidebar = () => {
        setSidebarExtended((prev) => !prev);
    };

    const handleClosePrompts = () => {
        setShowActivityCountPrompt(false);
        setShowContactUsPrompt(false);
    };

    const checkOneWeekInactive = (startDateMS: number) => {
        const currentTime = Date.now();

        // 7 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
        const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;

        const oneWeek = currentTime - startDateMS >= oneWeekInMilliseconds;
        const noActivity = room?.total_activities_launched === 0;

        return oneWeek && noActivity;
    };

    useEffect(() => {
        if (room?.id) {
            const hasGrades = room?.grades_taught?.length > 0;
            const hasSubjects = Object.keys(room?.subjects_taught ?? {}).length > 0;

            if (!hasGrades && !hasSubjects) {
                setShowOnboardingOverlay(true);
            }
        }
    }, [room]);

    const links = {
        premium: (
            <a
                target="_blank"
                rel="noreferrer"
                className={styles.link}
                href="https://myshortanswer.com/see-plans/"
            >
                Short Answer Premium
            </a>
        ),
        calendly: (
            <a
                target="_blank"
                rel="noreferrer"
                className={styles.link}
                href="https://calendly.com/shortansweradam/adamsmeeting"
            >
                Schedule a time to talk!
            </a>
        ),
        funding: (
            <a
                target="_blank"
                rel="noreferrer"
                className={styles.link}
                href="https://myshortanswer.com/funding-support/"
            >
                our funding support page
            </a>
        ),
    };

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((user) => {
            // @ts-ignore
            window.delighted.survey({
                email: user?.attributes?.email,
                name: user?.attributes?.name,
                createdAt: Date.now(),
                properties: {
                    plan: isPremium ? 'Premium' : 'Free',
                },
            });
        });
    }, []);

    useEffect(() => {
        if (room?.id) {
            const signUpDate = new Date(room.created_at).getTime();
            const oneWeekInactive = checkOneWeekInactive(signUpDate);

            if (oneWeekInactive) {
                setShowContactUsPrompt(true);
            } else if (
                room.total_activities_launched > 0 &&
                room.total_activities_launched % 10 === 0
            ) {
                setShowActivityCountPrompt(true);
            }

            setShowWelcomeModal(!room?.welcome_modal_dismissed);
        }
    }, [room?.id]);

    let contactUsMessage = (
        <span>
            My name&apos;s Adam, I&apos;m one of the creators of Short Answer! It looks
            like you haven&apos;t tried Short Answer with students yet. Want help getting
            started?
        </span>
    );

    if (showActivityCountPrompt) {
        contactUsMessage = (
            <span>
                You&apos;ve launched over {room?.total_activities_launched ?? 10} Short
                Answer activities! You should check out {links.premium} along with{' '}
                {links.funding}, we&apos;d love to better support your writing
                instruction!
            </span>
        );
    }

    if (showOnboardingOverlay) {
        return <OnboardingOverlay onClose={() => setShowOnboardingOverlay(false)} />;
    }

    return (
        <>
            <Sidebar
                show={sidebarExtended}
                onClickHambuger={handleExtendCollapseSidebar}
            />
            <WelcomeModal
                show={showWelcomeModal}
                onClose={() => setShowWelcomeModal((prev) => !prev)}
            />
            <div
                className={cn(styles.container, {
                    [styles.sidebar_extended]: sidebarExtended,
                })}
            >
                <Outlet />
                {!isPremium && (
                    <DashboardMessage
                        show={showContactUsPrompt || showActivityCountPrompt}
                        onClose={handleClosePrompts}
                    >
                        <div className={styles.message_container}>
                            <div className={styles.image_container}>
                                <ProfileImage
                                    profileImageClassName={styles.image}
                                    src={`${assetsUrlPrefix}/images/adam_headshot.JPG`}
                                />
                            </div>
                            <div className={styles.text}>
                                {contactUsMessage}
                                <div className={styles.link}>{links.calendly}</div>
                            </div>
                        </div>
                    </DashboardMessage>
                )}
            </div>
        </>
    );
};

export default Dashboard;

import React, { useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { useNavigate, NavLink } from 'react-router-dom';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import styles from 'styles/pages/Home.scss';
import { Colors } from '../themeVars.js';
import Button from '../atoms/Button.js';
import { WaitScreen } from '../templates/WaitScreen.js';
import LogoTextLink from '../atoms/LogoTextLink.js';
import { AnalyticsEvents } from '../../../types/AnalyticsEvents.js';
import { trackEvent } from '../../lib/analytics.js';

const Home = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(() => {
                navigate('/dashboard/questions');
            })
            .catch(() => null)
            .finally(() => setLoading(false));
    });

    if (loading) {
        return (
            <WaitScreen
                variant="indeterminate"
                loaderColor="yellow"
                message="Loading..."
            />
        );
    }

    return (
        <div className={styles.container}>
            <LogoTextLink linkClassName={styles.logo_text_link_link} />
            <Card className={styles.card}>
                <div className={styles.card_content}>
                    <Button
                        className={styles.button}
                        to="/sign-in"
                        kind="primary"
                        onClick={() =>
                            trackEvent(AnalyticsEvents.teacherSignInButtonClicked)
                        }
                    >
                        Teacher Sign In
                    </Button>
                    <Button
                        className={styles.button}
                        to="/join"
                        kind="secondary"
                        onClick={() =>
                            trackEvent(AnalyticsEvents.studentSignInButtonClicked)
                        }
                    >
                        Student Sign In
                    </Button>
                    <Divider className={styles.divider} />
                    <Typography variant="body1" className={styles.signup_link}>
                        New to Short Answer?{' '}
                        <NavLink
                            to="/sign-up"
                            style={{ color: Colors.ruby, whiteSpace: 'nowrap' }}
                        >
                            Sign up here
                        </NavLink>
                    </Typography>
                </div>
            </Card>
        </div>
    );
};

export default Home;
